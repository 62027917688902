import ApiClient from '@/libs/http-client';

const UserService = {
  changePassword(data) {
    return ApiClient.post('/user/change-password', data)
  },
  updateNotifications(data) {
    return ApiClient.post('/user/notifications', data)
  },
  resendEmailVerify() {
    return ApiClient.get('/user/verify/resend');
  },
  getTourState() {
    return ApiClient.get(`/user/step-tour/products`);
  },
  updateTourState(data) {
    return ApiClient.post(`/user/step-tour/products`, data);
  },
  changePersonalData(data) {
    return ApiClient.put(`/user/personal-data`, data);
  },
  updateAccType(data) {
    return ApiClient.put(`/user/account-type`, data);
  },

  /**
   * Customers Proxy
   */
  getCustomersProxy() {
    return ApiClient.get(`/user/proxy`);
  },
  getInstagramAccounts() {
    return ApiClient.get('/user/proxy/accounts');
  },
  saveCustomersProxy(data) {
    return ApiClient.post(`/user/proxy`, data);
  },
  assignCustomersProxy(payload) {
    return ApiClient.post(`/user/proxy/assign`, payload);
  },
  updateCustomersProxy(proxyId, payload) {
    return ApiClient.put(`/user/proxy/${ proxyId }/update`, payload);
  },
  removeCustomersProxy(proxyId) {
    return ApiClient.delete(`/user/proxy/${ proxyId }/delete`);
  },
  checkCustomersProxy({ type, data }) {
    return ApiClient.post(`/user/proxy/check?type=${type}`, data);
  }
}

export default UserService;
