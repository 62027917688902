import ApiClient from '@/libs/http-client';

const HashtagService = {
    searchHashtags(data) {
        return ApiClient.post(`/ig/hashtag-generator/search`, data)
    },

    getSavedTemplates() {
        return ApiClient.get('/hashtag-generator/templates');
    },

    createNewTemplate(data) {
        return ApiClient.post('/hashtag-generator/templates', data)
    },

    updateSavedTemplate(templateId, data) {
        return ApiClient.put(`/hashtag-generator/templates/${templateId}`, data)
    },

    removeSavedTemplate(templateId) {
        return ApiClient.delete(`/hashtag-generator/templates/${templateId}`)
    }

}

export default HashtagService;
