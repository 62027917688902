<template>
  <a v-if="analyzeRoute.type === 'external'" :href="analyzeRoute.url" v-bind:target="targetAttr">
    <slot />
  </a>

  <p v-else-if="analyzeRoute.type === 'page'" v-scroll-to="analyzeRoute.to">
    <slot />
  </p>

  <router-link v-else-if="analyzeRoute.type === 'internal'" :to="analyzeRoute.to"  v-bind:target="targetAttr" :aria-label="linkUrl">
    <slot />
  </router-link>
</template>

<script>
  import appRoutes from '@/router/routes/app.routes';
  import landingRoutes from '@/router/routes/landing.routes';

  export default {
    name: 'AppLink',
    inheritAttrs: false,
    props: {
      linkUrl: String,
      linkType: {
        type: String,
        enum: ['app', 'anchor', 'landing', 'external']
      },
      linkName: String,
      linkQuery: {
        type: Object,
        default() {
          return {}
        }
      },
      linkParams: {
        type: Object,
        default() {
          return {}
        }
      },
      blank: Boolean
    },
    data() {
      return {
				appRoutes,
				landingRoutes,
        landingAnchors: ['check-your-growth', 'why-insocial', 'pricing', 'case-studies' ],
        landingProducts: ['auto-promotion','auto-boost','direct-messaging','schedule-posting','comment-tracker','hashtag-generator','influencer-discovery']
      }
    },
    computed: {
      targetAttr() {
        return this.blank ? '_blank' : null;
      },
      routePath() {
        return this.$route.path;
      },
      appOrigin() {
        const app = process.env.VUE_APP_URL;
        const landing = process.env.VUE_APP_LANDING;
        const current = window.location.origin;

        return {
          app, landing, current
        }
      },
      routesBase() {
        const app = this.normalizeRoutes(this.appRoutes, ['/', '/auth']);
        const landing = this.normalizeRoutes(this.landingRoutes, ['/']);

        landing.splice(
            landing.indexOf(landing.find(i => i.name === 'ProductsPreviewPage')),
            1,
            ...this.landingProducts.map(p => {
              let { name, path } = app.find(i => i.path === `/${ p }`);

              return { name: name.replace('Page', 'PreviewPage'), path: `/products${ path }` }
            }),
        );

        landing.push(...this.normalizeAnchors(this.landingAnchors));

        return { app, landing }
      },
      analyzeRoute() {
        if (this.linkType === 'external') return { type: 'external', url: this.linkUrl }

        if (this.linkType === 'anchor') {
          let route = this.routesBase['landing'].find(i => i.name === this.linkName);

          if (this.appOrigin.current !== this.appOrigin['landing'])
						return { type: 'external', url: `${ this.appOrigin['landing'] }/${ route.hash }`};
					else {
            if (this.routePath !== '/')
							return {
								type: 'internal',
								to: {
									path: route.path,
									hash: route.hash,
									query: this.linkQuery,
									params: this.linkParams,
								}
							};
						else return { type: 'page', to: `${ route.hash }` }
          }
        } else {
          let route = this.routesBase[this.linkType].find(i => i.name === this.linkName);

          if (this.appOrigin.current !== this.appOrigin[this.linkType])
						return { type: 'external', url: `${ this.appOrigin[this.linkType] }${ route?.path }` }
          else return {
						type: 'internal',
						to: {
							path: route?.path,
							hash: route.hash === undefined ? '' : route.hash,
							query: this.linkQuery,
							params: this.linkParams
						}
					}
        }
      }
    },
    methods: {
      normalizeRoutes(routes, paths) {
        let router = [];
        const tempArr = paths
						.map(p => {
							let entry = routes.find(r => r.path === p);
							if (entry.name !== undefined) router.push({ name: entry.name, path: entry.path });

							return entry;
						})
						.map(arr =>
								arr.children.map(c =>
										({ name: c.name, path: `${ arr.path }${ arr.path === '/' ? '' : '/'}${ c.path }`})
								)
						);

        return router.concat(...tempArr);
      },
      normalizeAnchors(anchors) {
        return anchors
						.map(a => ({
							path: '/',
							hash: `#${ a }`,
							name: a.split('-').map(p => this.lodash.capitalize(p)).join('') + 'Anchor'
						}));
      }
    }
  }
</script>
