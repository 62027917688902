<template>
	<transition name="fade" appear>
		<div v-if="isVisible" :class="['confirm-dialog', `offset-top-${offsetTop}`]">
			<div class="background"></div>

			<div class="confirm-dialog-card">
				<div class="confirm-dialog-card__header">
					<div class="header-title">
						{{ title }}
					</div>

					<img
							@click="_actionCancel"
							class="close-icon"
							src="@/assets/images/close-black.svg"
							alt="confirm dialog close icon" />
				</div>

				<div class="confirm-dialog-card__content" v-html="contentText" />

				<div class="confirm-dialog-card__footer">
					<button @click="_actionConfirm" :class="confirmButton.class"> {{ confirmButton.text }} </button>
					<button @click="_actionCancel" class="cancel-btn">Cancel</button>
				</div>
			</div>
		</div>
	</transition>
</template>


<script>
export default {
	name: 'ConfirmDialog',
	props: {
		offsetTop: {
			type: String,
			default: '50'
		},
	},
	data() {
		return {
			isVisible: false,
			title: 'confirmation required',
			contentText: '',
			confirmButton: {
				text: 'confirm',
				class: 'confirm-btn'
			},

			resolvePromise: undefined,
			rejectPromise: undefined
		}
	},
	methods: {
		toggleConfirmState(state) {
			this.isVisible = state;
		},

		show(opts = {}) {
      // debugger;
			this.title = this.lodash.isUndefined(opts.title) ? this.title : opts.title;
			this.contentText = opts.contentText;
			this.confirmButton = {
				text: this.lodash.isUndefined(opts.confirmButtonText) ? this.confirmButton.text : opts.confirmButtonText,
				class: this.lodash.isUndefined(opts.confirmButtonClass) ? this.confirmButton.class : opts.confirmButtonClass
			};

			this.toggleConfirmState(true);

			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			})
		},

		_actionCancel() {
			this.toggleConfirmState(false);
			this.resolvePromise(false);
		},

		_actionConfirm() {
			this.toggleConfirmState(false);
			this.resolvePromise(true);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';

.confirm-dialog {
	position: absolute;
	left: calc(50% - 200px);
	background: #FFFFFF;
	border-radius: 8px;
	z-index: 1000;
	box-shadow: 0 2px 8px #03030326;

	&.offset-top {
		&-25 {
			top: calc(25% - 100px);
		}
		&-50 {
			top: calc(50% - 100px);
		}
	}

	@include _592 {
		left: 20%
	}

	@include _450 {
		left: 10%
	}

	.background {
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.411);
		width: 100vw;
		height: 100%;
		z-index: -10;
	}

	&-card {
		width: 400px;
		box-shadow: 0 2px 8px rgba(3, 3, 3, 0.15);
		border-radius: 8px;

		@include _592 {
			width: 300px;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px 24px 16px 16px;
			background: #F8F8F8;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			@include _768 {
				padding: 12px;
			}

			@include _592 {
				padding: 8px;
			}

			.header-title {
				color: $dark-blue;
				font-weight: 500;
				font-size: 18px;
				text-transform: capitalize;

				@include _592 {
					font-size: 15px;
				}
			}
			.close-icon {
				margin: 5px;
				cursor: pointer;

				@include _592 {
					margin: 3px;
				}
			}
		}

		&__content {
			padding: 32px 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			color: $dark-grey;
			border-top: 2px solid $soft-grey;
			background: #ffffff;

			@include _592 {
				font-size: 15px;
				padding: 16px 8px;
			}
		}

		&__footer {
			border-top: 2px solid $soft-grey;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffffff;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			padding: 10px;
			@include _592 {
				flex-direction: column-reverse;
			}

			button {
				width: 180px;
				height: 44px;
				margin: 8px 4px 8px 8px;
				border-radius: 4px;
				background: #FFFFFF;
				font-weight: 500;
				font-size: 16px;
				cursor: pointer;
				color: $dark-blue;
				text-transform: capitalize;

				&:hover {
					opacity: 0.5;
				}

				&.cancel-btn {
					border: 2px solid #07166C;
				}

				&.confirm-btn, &.sub-activate-btn {
					border: none;
					background: #38D89A;
				}

				&.sub-suspend-btn {
					background: #366ad7;
					color: #FFFFFF;
				}

				&.sub-cancel-btn {
					background: #ff4f4f;
					color: #FFFFFF;
					border: 2px solid #b30606;
				}

				@include _768 {
					height: 45px;
				}

				@include _592 {
					margin: 5px 5px 10px 5px;
				}
			}
		}
	}
}

.fade {
	&-enter {
		opacity: 0;

		&-active {
			transition: all 0.3s ease-out;
		}
	}

	&-leave {
		&-active {
			transition: all 0.3s ease-out;
		}

		&-to {
			opacity: 0;
		}
	}
}
</style>
