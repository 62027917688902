import config from '@/config';
import { messages } from '@/libs/validator';

import lodash from 'lodash';
import VueGtag from 'vue-gtag';
import VueLodash from 'vue-lodash';
import Vuelidate from 'vuelidate';
import googleAuth from 'vue-google-oauth2';
import vueDebounce from 'vue-debounce';
import VueLazyload from 'vue-lazyload';
import ClickOutside from 'vue-click-outside';
import VuelidateErrorExtractor from 'vuelidate-error-extractor';
import FormFieldWrapper from '@/components/app/core/FormFieldWrapper';
import LoadScript from 'vue-plugin-load-script';
import browserDetect from "vue-browser-detect-plugin";
import VuePlyr from 'vue-plyr';
import Vue2TouchEvents from 'vue2-touch-events';
import VTooltip from 'v-tooltip';
import vSelect from 'vue-select'

const utilityGlobal = (Vue, router) => {
    Vue.use(Vuelidate);
    Vue.use(ClickOutside);
    Vue.use(Vue2TouchEvents, {
        disableClick: false,
        touchClass: '',
        tapTolerance: 10,
        touchHoldTolerance: 600,
        swipeTolerance: 30,
        longTapTimeInterval: 400,
        namespace: 'touch'
    })

    Vue.component('v-select', vSelect)

    Vue.use(VuePlyr, {
        plyr: {
            options: {
                debug: true
            }
        },
    });

    Vue.use(LoadScript);

    Vue.use(VuelidateErrorExtractor, {
        messages: messages,
        template: FormFieldWrapper
    });

    Vue.use(VueLazyload, config.lazyLoad);

    // Google Analytics
    Vue.use(VueGtag, config.gtag, router);

    // Google Authenticate button
    Vue.use(googleAuth, config.gAuth);

    Vue.use(vueDebounce, config.debounce);

    Vue.use(VueLodash, { name: lodash, lodash: lodash });

    Vue.use(VTooltip);

    Vue.use(browserDetect);
};

export default utilityGlobal;
