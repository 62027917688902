import ApiClient from '@/libs/http-client';

const PostingService = {
  loadInstagramScheduledTasks(instagramId) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/media/scheduled`);
  },
  loadInstagramMediaPosts(instagramId, state) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/media/posts?state=${ state }`);
  },
  loadInstagramMediaStories(instagramId) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/media/stories`);
  },
  loadInstagramLocations(instagramId, query) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/location?${ query }`);
  },
  createPostScheduleTask(instagramId, data, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/posts/create`, data, config);
  },
  updatePostScheduleTask(taskId, instagramId, data, status, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/posts/${ taskId }/update?${ status }`, data, config);
  },
  postPublishNow(instagramId, data, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/posts/publish`, data, config);
  },
  postPublishScheduleTask(taskId, instagramId) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/posts/${ taskId }/schedule/publish`);
  },
  postUpdateAndPublishScheduleTask(taskId, instagramId, data, status, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/posts/${ taskId }/schedule/update/publish?${ status }`, data, config);
  },
  createStoryScheduleTask(instagramId, data, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/story/create`, data, config);
  },
  updateStoryScheduleTask(taskId, instagramId, data) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/story/${ taskId }/update`, data);
  },
  storyPublishNow(instagramId, data, config) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/story/publish`, data, config);
  },
  storyPublishScheduleTask(taskId, instagramId) {
    return ApiClient.get(`/schedule-posting/${ instagramId }/story/${ taskId }/schedule/publish`);
  },
  storyUpdateAndPublishScheduleTask(taskId, instagramId, data) {
    return ApiClient.post(`/schedule-posting/${ instagramId }/story/${ taskId }/schedule/update/publish`, data);
  },
  deleteScheduleTask(taskId, instagramId) {
    return ApiClient.delete(`/schedule-posting/${ instagramId }/${ taskId }/task`);
  },
  deleteScheduleTaskMedia(taskId, instagramId, mediaName) {
    return ApiClient.delete(`/schedule-posting/${ instagramId }/${ taskId }/${ mediaName }/media`);
  }
};

export default PostingService;
