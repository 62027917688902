import AuthService from '@/services/api/auth.service';
import UserService from '@/services/api/user.service';

const initialState = {
    user: {},
    proxy: [],
    authToken: null,
    isAuthenticated: false,
};

const state = JSON.parse(JSON.stringify(initialState));

const handleSetUserProfile = (payload, commit, request, _this) => {
    commit('SET_USER_PROFILE', payload.user);

    if (request) {
        _this._vm.$socket.request({
            method: 'get',
            url: `/auth/private-channel`,
            headers: {
                'authorization': payload.user.authToken
            }
        }, payload => console.info(`Insocial.io Socket --> User ${ payload.message }`))
    }

    return payload;
};
const handleSetAccessPaymentAndTours = (payload, commit) => {
    const { message, billingServicePlan, productsTour, paysimple } = payload;

    commit('app/SET_ALLOWED_PRODUCTS', billingServicePlan, { root: true });
    commit('billing/SET_BILLING_PLAN', billingServicePlan, { root: true });
    commit('billing/SET_PAYSIMPLE_SETTINGS', paysimple, { root: true });
    commit('app/SET_PRODUCTS_TOUR', productsTour, { root: true });

    return message;
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)))
    },
    SET_ACCESS_TOKEN(state, token) {
        state.authToken = token;
    },
    SET_USER_PROFILE(state, payload) {
        state.isAuthenticated = true;
        state.authToken = payload.authToken;

        state.user = payload;
    },
    SET_USER_VERIFY(state) {
        state.user.isVerified = true;
    },
    SET_USER_NOTIFICATIONS(state, payload) {
        state.user.notifications = payload;
    },
    SET_USER_PERSONAL_DATA(state, payload){
        state.user.fullName = payload.fullName;
        state.user.displayName = payload.displayName;
    },
    SET_ACC_TYPE(state, payload){
        state.user.accountType = payload
    },
    SET_CUSTOMERS_PROXY(state, payload) {
        state.proxy = payload;
    },
    ADD_CUSTOMERS_PROXY(state, payload) {
        state.proxy.push(payload);
    },
        UPDATE_CUSTOMERS_PROXY(state, payload) {
        const idx = state.proxy.findIndex(_ => _.id === payload.id);

        state.proxy.splice(idx, 1, payload);
    },
    REMOVE_CUSTOMERS_PROXY(state, proxyId) {
        const idx = state.proxy.findIndex(_ => _.id === proxyId);

        state.proxy.splice(idx, 1);
    }
};

const actions = {
    localLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
            AuthService
                .localLogin(data)
                .then(({ data }) => handleSetUserProfile(data, commit, true, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    },
    localLogout({ dispatch }) {
        return new Promise((resolve, reject) => {
            AuthService
                .localLogout()
                .then(response => {
                    this._vm.$eventHub.$emit('pre-logout-loading');
                    dispatch('app/clearAppStorage', {}, { root: true });

                    return resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    },
    localRegister({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .localRegister(data)
                .then(({ data }) => handleSetUserProfile(data, commit, true, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch((error) => reject(error.message));
        });
    },
    // eslint-disable-next-line no-empty-pattern
    recoveryInit({}, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .recoveryInit(data)
                .then(response => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    recoveryComplete({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .recoveryComplete(data)
                .then(({ data }) => handleSetUserProfile(data, commit, true, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch((error) => reject(error));
        });
    },
    confirmEmail({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .accountConfirm(data)
                .then(({ data }) => handleSetUserProfile(data, commit, false, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch((error) => reject(error));
        });
    },
    accountConfirmWebsocket({ commit }, payload) {
        return new Promise(resolve => {
            const setProfile = handleSetUserProfile(payload, commit, true, this);

            const message = handleSetAccessPaymentAndTours(setProfile, commit);

            return resolve(message);
        })
    },
    // eslint-disable-next-line no-empty-pattern
    verifyToken({}, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .verifyToken(data)
                .then(response => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    facebookLogin({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .facebookLogin(data)
                .then(({ data }) => handleSetUserProfile(data, commit, true, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch((error) => reject(error));
        });
    },
    googleLogin({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService
                .googleLogin(data)
                .then(({ data }) => handleSetUserProfile(data, commit, true, this))
                .then(payload => handleSetAccessPaymentAndTours(payload, commit))
                .then(response => resolve(response))
                .catch((error) => reject(error));
        });
    },
    // eslint-disable-next-line no-empty-pattern
    changeUserPassword({}, data) {
        return new Promise((resolve, reject) => {
            UserService
                .changePassword(data)
                .then(response => resolve(response.data.message))
                .catch(error => reject(error.message));
        })
    },
    manageAppNotifications({ commit }, data) {
        return new Promise((resolve, reject) => {
            UserService
                .updateNotifications(data)
                .then(response => {
                    commit('SET_USER_NOTIFICATIONS', response.data.user.notifications);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    resendEmailVerification() {
        return new Promise((resolve, reject) => {
            UserService
                .resendEmailVerify()
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },
    changePersonalData({ commit }, data) {
        return new Promise((resolve, reject) => {
            UserService
                .changePersonalData(data)
                .then(response => {
                    commit('SET_USER_PERSONAL_DATA', {
                        fullName: `${data.firstname} ${data.lastname}`,
                        displayName: data.email
                    });

                    return resolve(response.data.message);
                })
                .catch(error => reject(error));
        })
    },
    updateAccType({ commit }, data) {
        return new Promise((resolve, reject) => {
            UserService
                .updateAccType(data)
                .then(response => {
                    commit('SET_ACC_TYPE', data.type);
                    return resolve(response.data.message);
                })
                .catch(error => reject(error));
        })
    },

    /**
     * Customers Proxy
     */
    getCustomersProxy({ commit }) {
        return new Promise((resolve, reject) => {
            UserService
                .getCustomersProxy()
                .then(response => {
                    commit('SET_CUSTOMERS_PROXY', response.data);

                    return resolve(response.data);
                })
                .catch(error => reject(error.message));

        })
    },
    // eslint-disable-next-line no-unused-vars
    getInstagramAccounts({ commit }) {
        return new Promise((resolve, reject) => {
            UserService
                .getInstagramAccounts()
                .then(response => {

                    return resolve(response.data);
                })
                .catch(error => reject(error.message));

        })
    },
    saveCustomersProxy({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .saveCustomersProxy(payload)
                .then(response => {
                    commit('ADD_CUSTOMERS_PROXY', response.data.proxy._settledValueField);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-unused-vars
    assignCustomersProxy({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .assignCustomersProxy(payload)
                .then(response => resolve(response.data.message))
                .catch(error => reject(error.message));
        })
    },
    updateCustomersProxy({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .updateCustomersProxy(payload.proxyId, payload.data)
                .then(response => {
                    commit('UPDATE_CUSTOMERS_PROXY', response.data.proxy._settledValueField);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message));
        })
    },
    removeCustomersProxy({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .removeCustomersProxy(payload)
                .then(response => {
                    commit('REMOVE_CUSTOMERS_PROXY', payload);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message))
        })
    },
    // eslint-disable-next-line no-unused-vars
    checkCustomersProxy({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .checkCustomersProxy(payload)
                .then(response => {

                    return resolve(response.data);
                })
                .catch(error => reject(error.message));
        })
    }
};

const getters = {
    userProfile: state => state.user,
    userAccessToken: state => state.authToken,
    isUserVerified: state => state.user.isVerified,
    isUserAuthenticated: state => state.isAuthenticated,

    appNotifications: state => state.user.notifications,

    userProxies: state => state.proxy,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
