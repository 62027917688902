/**
 * This file contains functions that enhance the event system on Vue.prototype
 */


/**
 * Global event Hub
 * @param Vue Vue constructor function
 */
export const useGlobalEventHub = Vue => Vue.prototype.$eventHub = new Vue();


/**
 * Implement components ancestors/descendants Event Broadcast mechanism
 * @param Vue Vue constructor function
 */
export const useComponentsTreeEventBroadcast = Vue => {
    /**
     * Implement Upward Broadcast event mechanism
     * Broadcast an composables from the current component upward to all the ancestors
     * @param event: string - the composables being broadcast upward from the current component
     * @param args: any - the data being passed when emitting the composables
     */
    Vue.prototype.$upwardBroadcast = function(event, args) {
        let parent = this.$parent;

        while(parent) {
            parent.$emit(event, args);
            parent = parent.$parent;
        }
    }

    /**
     * Implement Downward Broadcast event mechanism
     * Broadcast an composables from the current component upward to all the descendants
     * @param event: string - the composables being broadcast downward from the current component
     * @param args: any - the data being passed when emitting the composables
     */
    Vue.prototype.$downwardBroadcast = function(event, args) {
        const downwardBroadcast = children => {
            children.forEach(child => {
                child.$emit(event, args);

                if (child.$children) downwardBroadcast(child.$children);
            });
        }
        downwardBroadcast(this.$children);
    }
};
