<template>
  <div :class="[`app-preloader-container`, {'loading-container': loadingText, 'error-container': errorText }]">
    <div class="app-preloader-spinner">
      <loading
          :active.sync="isLoading"
          :can-cancel="canCancel"
          :is-full-page="isFullPage"
          :height="height"
          :width="width"
          :loader="loader"
          :background-color="backgroundColor"
          :color="color"
          :opacity="opacity"
          :blur="blur"
          :zIndex="4"
          transition="bounce"
          :lock-scroll="lockScroll"
          style="align-items: unset; z-index: 4"
      >
      </loading>
    </div>
    <div class="app-preloader-loading-text" style="z-index: 4;">
      <span
          :class="`let${ idx + 1 }`" :key="idx"
          v-for="(char, idx) in formattedLoadingText"
      >
        {{ char }}
      </span>
    </div>
    <div class="app-preloader-error-text">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoading',
    props: {
      loadingText: {
        type: String,
        default: 'loading'
      },
      errorText: String,
      isLoading: {
        type: Boolean,
        default: false
      },
      isFullPage: {
        type: Boolean,
        default: false
      },
      canCancel: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number,
        default: 50
      },
      width: {
        type: Number,
        default: 50
      },
      loader: {
        type: String,
        enum: ['spinner', 'dots', 'bars'],
        default: 'spinner'
      },
      backgroundColor: String,
      color: {
        type: String,
        default: 'rgb(54,106,215)'
      },
      opacity: Number,
      blur: String,
      lockScroll: Boolean,
    },
    computed: {
      formattedLoadingText() {
        return this.loadingText.split('');
      }
    }
  }
</script>

<style lang="scss" scoped>
@mixin animationDelay {
  $delay: 1.1;
  $maxElements: 20;

  @for $i from 0 to $maxElements {
    &.let#{$i} {
      animation: drop 1.2s ease-in-out infinite;
      animation-delay: #{calc($i / 10) + $delay}s;
    }
  }
}
.app-preloader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 125px;
  margin-top: 25px;

  &.loading-container {
    justify-content: center;
  }

  &.error-container {
    justify-content: space-evenly;
  }

  .app-preloader-spinner {

  }
  .app-preloader-loading-text {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 1.1em;
    color: #366ad7;

    span {
      position: relative;
      top: 0.63em;
      display: inline-block;
      text-transform: uppercase;
      opacity: 0;
      transform: rotateX(-90deg) translate3d(0, 0, 0);
      -webkit-transform: rotateX(-90deg) translate3d(0, 0, 0);

      @include animationDelay;

      @keyframes drop {
        10% {
          opacity: 0.5;
        }
        20% {
          opacity: 1;
          top: 35px;
          transform: rotateX(-360deg);
        }
        80% {
          opacity: 1;
          top: 35px;
          transform: rotateX(-360deg);
        }
        90% {
          opacity: 0.5;
        }
        100% {
          opacity: 0;
          top: 60px;
        }
      }
    }
  }

  .app-preloader-error-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.1em;
    color: #366ad7;
  }
}
</style>
