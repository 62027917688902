import ApiClient from '@/libs/http-client';

const InfluencerService = {
    getLocation(payload){
        return ApiClient.post('/influencers/geos', payload)
    },
    //get Filters Options (Langs, Brands, Interests, ) ** temporary was saved as static JSON /src/assets/data
    getLangs(){
        return ApiClient.get('/influencers/langs')
    },
    getInterestsBrands(){
        return ApiClient.get('/influencers/interests-and-brands')
    },
    getSearchResult(payload) {
        return ApiClient.post('/influencers/discovery', payload)
    },
    getInfluencerProfile(payload){
        return ApiClient.post(`/influencers/details/report`, payload)
    },
    getRelevantTopicsOptions(searchQuery){
        return ApiClient.get(`/influencers/topic/tags?search=${searchQuery}`)
    },
    getLookaLikeOptions(searchQuery){
        return ApiClient.get(`/influencers/lookalike/users?search=${searchQuery}`)
    },

    getInfluencersReportList(searchQuery){
        return ApiClient.get(`/influencers/reports/list?q=${searchQuery}`)
    },
    getInfluencersReportFetch(profileId){
        return ApiClient.get(`/influencers/reports/${profileId}/fetch`)
    },


    getSavedLists(){
        return ApiClient.get('/influencers/lists')
    },
    getOpenedInfluencersList(){
        return ApiClient.get('/influencers/lists/recent')
    },
    createInfluencersList(payload){
        return ApiClient.post('/influencers/lists/create', payload)
    },
    updateInfluencersList(listId, action, payload){
        return ApiClient.post(` /influencers/lists/${listId}/update/${action}`, payload)
    },
    removeInfluencersList(listId){
        return ApiClient.delete(`/influencers/lists/${listId}/remove`)
    },
    renameInfluencersList(payload){
        return ApiClient.post(`/influencers/lists/${payload.listId}/rename`, { label: payload.label})
    },
    createNewReport(payload){
        return ApiClient.post('/influencers/reports/new', payload)
    },
    addInfluencerToList(payload){
        return ApiClient.post(`/influencers/lists/${payload.listId}/profiles/add`, {profiles: payload.influencerId})
    },
    removeInfluencerFromList(payload){
        return ApiClient.post(`/influencers/lists/${payload.listId}/profiles/remove`, {profiles: payload.influencerId})
    },
    unhideNextChunk(payload){
        return ApiClient.post('/influencers/profiles/unlock', payload)
    },
    getReportPDF(reportId){
        return ApiClient.get(`/influencers/reports/${reportId}/pdf`, {responseType: 'blob'});
    }
}
export default InfluencerService;

