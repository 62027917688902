import config from "@/config";
import moment from "moment";
import momentTZ from "moment-timezone";
import socketClient from "@/libs/socket-client";
import defaultAvatar from "@/assets/images/avatar/Avatar.png";

import {
  useGlobalEventHub,
  useComponentsTreeEventBroadcast,
} from "@/hooks/events.hook";

const defineAppPrototypes = (Vue) => {
  /* App Global Event Hooks */
  useGlobalEventHub(Vue);
  useComponentsTreeEventBroadcast(Vue);

  Vue.prototype.$moment = moment;
  Vue.prototype.$momentTZ = momentTZ;

  /* Sails WebSocket Client */
  Vue.prototype.$socket = socketClient.connect();

  /* Toasts */
  Vue.prototype.$showSuccess = function (msg, timer) {
    if (timer !== undefined) config.toastMessages.timer = timer;

    Vue.swal({
      text: msg,
      icon: "success",
      ...config.toastMessages,
    });
  };

  // eslint-disable-next-line no-unused-vars
  Vue.prototype.$showDialog = function (
    title,
    confirmButtonText,
    cancelButtonText
  ) {
    return new Promise((resolve) => {
      Vue.swal({
        title,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        icon: "warning",
        confirmButtonText,
        cancelButtonText,
      }).then((result) => {
        resolve(result);
      });
    });
  };

  Vue.prototype.$showError = function (msg, timer) {
    if (timer !== undefined) config.toastMessages.timer = timer;

    Vue.swal({
      text: msg,
      icon: "error",
      ...config.toastMessages,
    });
  };

  /* Get Global Reactive Account State handler */
  Vue.prototype.$igAccount = new Vue({
    data() {
      return {
        defaultAvatar,
        selectedAccountInfo: null,
        errorFields: [
          "challenge",
          "checkpointRequired",
          "errorName",
          "loginRequired",
          "notFoundError",
          "undefinedError",
        ],
        successField: "profile",
      };
    },
    methods: {
      OnErrorLoadingAvatar(e) {
        return (e.target.src = this.defaultAvatar);
      },
      checkUnknownIGError(acc) {
        const keysToCheck = [
          "challenge",
          "checkpoint",
          "checkpointRequired",
          "errorMessage",
          "errorName",
          "loginRequired",
          "notFoundError",
          "profile",
          "undefinedError",
        ];

        return keysToCheck.every((key) => !acc[key]);
      },
      checkState(acc) {
        this.selectedAccountInfo = acc;
        const actionState = false;
        // const errorState = this.errorFields.some(field => !!acc[field]) && !acc[this.successField];
        const errorState =
          this.errorFields.some((field) => !!acc[field]) ||
          acc.twoFactor.state ||
          this.checkUnknownIGError(acc);
        const successState =
          this.errorFields.every((field) => !acc[field]) &&
          acc[this.successField];

        if (errorState) return "account-state-error";
        if (actionState) return "account-state-action";
        if (successState) return "account-state-success";
      },
    },
  });

  /* Get Global Reactive Screen sizes */
  Vue.prototype.$screen = new Vue({
    data() {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    computed: {
      isMobile() {
        return this.width <= 767;
      },
      isTablet() {
        return this.width > 768 && this.width < 1023;
      },
      isDesktop() {
        return this.width > 1024;
      },
    },
    created() {
      window.addEventListener("resize", this.onWindowResize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
      onWindowResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      },
    },
  });

  // Vue.config.errorHandler = (err, vm, info) => {
  //     const errorString = `errorName: ${err.name}|info: ${info}|component: ${vm.$vnode.tag}`
  //     vm.$gtag.event('exception', {
  //         fatal: false,
  //         description: errorString
  //     })
  //     return { err, vm, info }
  // }

  /**
   * Vue service config
   */
  Vue.config.productionTip = process.env.VUE_APP_MODE !== "production";
  Vue.config.performance = process.env.VUE_APP_MODE !== "production";
};

export default defineAppPrototypes;
