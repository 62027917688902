import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';
import config from '@/config';
const appMode = process.env.VUE_APP_MODE;
const nodeEnv = process.env.NODE_ENV;
import { version } from '../../package.json';

const availableEnvironments = {
	development: 'dashboard_development',
	production: 'dashboard_production',
	stage: 'dashboard_stage',
}

export const initBugsnag = (vue) => {
	const bugsnagVue = Bugsnag.getPlugin('vue');
	bugsnagVue.installVueErrorHandler(vue);
};

const bugsnagConfig = {
	apiKey: config.bugSnag.key,
	hostname: 'insocial_dashboard',
	serviceName: "insocial_dashboard",
	metadata: {
		appName: "insocial_dashboard",
	},
	releaseStage: `${availableEnvironments[appMode]}` ?? nodeEnv,
	appVersion: version,
	enabledReleaseStages: Object.values(availableEnvironments),
}

Bugsnag.start({
	...bugsnagConfig,
	plugins: [new BugsnagPluginVue()]
});
BugsnagPerformance.start({
	...bugsnagConfig,
});
