import ApiClient from '@/libs/http-client';

const AuthService = {
    localLogin(data) {
        return ApiClient.post('/auth/local/login', data);
    },
    localLogout() {
        return ApiClient.get('/auth/local/logout');
    },
    localRegister(data) {
        return ApiClient.post('/auth/local/register', data);
    },
    recoveryInit(data) {
        return ApiClient.post('/auth/local/password-recovery/init', data);
    },
    recoveryComplete(data) {
        return ApiClient.post('/auth/local/password-recovery/complete', data);
    },
    accountConfirm(data) {
        return ApiClient.post('/auth/local/account-confirm', data);
    },
    verifyToken(data) {
        return ApiClient.post('/auth/verify-token', data);
    },
    facebookLogin(data) {
        return ApiClient.post('/auth/facebook/login', data);
    },
    googleLogin(data) {
        return ApiClient.post('/auth/google/login', data);
    }
}

export default AuthService;
