const landingRoutes = [
    {
        path: '/',
        redirect: '',
        children: [
            {
                path: '',
                name: 'LandingPage',
                meta: {
                    title: 'Marketing Toolkit for Instagram',
                    sitemap: {
                        priority: 1
                    }
                }
            },
            {
                path: 'contact-us/',
                name: 'contactUsPage',
                meta: {
                    title: 'Contact Us',
                    sitemap: {
                        priority: 1
                    }
                }
            },
            {
                path: 'disclaimer',
                name: 'DisclaimerPage',
                meta: {
                    title: 'Disclaimer',
                    sitemap: {
                        priority: 1
                    }
                }
            },
            {
                path: 'growth',
                name: 'GrowthPage',
                meta: {
                    title: 'growth',
                    sitemap: {
                        priority: 1
                    }
                }
            },
            {
                path: 'careers',
                name: 'CareersPage',
                meta: {
                    title: 'Careers',
                    sitemap: {
                        priority: 0.4
                    }
                }
            },
            {
                path: 'team',
                name: 'OurTeamPage',
                meta: {
                    title: 'Our Team',
                    sitemap: {
                        priority: 0.4
                    }
                },
            },
            {
                path: 'faq',
                name: 'FaqPage',
                meta: {
                    title: 'FAQ',
                    sitemap: {
                        priority: 0.8
                    }
                }
            },
            {
                path: 'terms',
                name: 'TermsOfServicePage',
                meta: {
                    title: 'Terms and Conditions',
                    sitemap: {
                        ignoreRoute: false,
                        priority: 0.8
                    }
                }
            },
            {
                path: 'privacy',
                name: 'PrivacyPolicyPage',
                meta: {
                    title: 'Privacy Policy',
                    sitemap: {
                        priority: 0.8
                    }
                }
            },
            {
                path: 'products/:product',
                name: 'ProductsPreviewPage',
                meta: {
                    ignoreRoute: false,
                    title: 'Preview',
                    sitemap: {
                        priority: 1,
                        paramKey: ':product',
                        paramValues: [
                            'auto-promotion',
                            'auto-boost',
                            'direct-messaging',
                            'schedule-posting',
                            'comment-tracker',
                            'hashtag-generator',
                            'influencer-discovery'
                        ]
                    }
                }
            }
        ]
    },
];

export default landingRoutes;
