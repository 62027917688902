import LandingService from '@/services/api/landing.service';
import InstagramService from "@/services/api/instagram.service";

const handleCYGlimits = (_this, timerKey, triesKey) => {
    let HOUR = 60 * 60 * 1000;
    let counter = parseInt(localStorage.getItem(triesKey)) || 0;
    let lastTime = parseInt(localStorage.getItem(timerKey));
    let now = Date.now();

    if (!lastTime || now - lastTime > HOUR) {
        localStorage.setItem(timerKey, now);
        localStorage.setItem(triesKey, 1);
        return true;
    } else if (counter < 5) {
        localStorage.setItem(triesKey, counter + 1);

        return true;
    } else {
        _this._vm.$showError('Sorry, but you’ve reached the limit of 5 account analyses. For security reasons, please wait 1 hour before trying again.');

        return false;
    }
};

const state = {
    landing: {},
    growthStatistics: null,
    checkGrowthAcc: null,
};

const mutations = {
    SET_GROWTH_STATISTICS(state, payload){
        state.growthStatistics = payload
    },
    SET_GROWTH_ACCOUNT(state, payload){
        state.checkGrowthAcc = payload
    },
};

const actions = {
    // eslint-disable-next-line no-empty-pattern
    submitLandingForm({}, payload) {
        return new Promise((resolve, reject) => {
            LandingService
                .submitLandingForms(payload)
                .then(response => resolve(response.data.message))
                .catch(error => reject(error.message))
        });
    },
    //checkYourGrowthSection
    // eslint-disable-next-line no-empty-pattern
    handleLoadInstagramAccountsSearch({}, accName) {
        const checkLimit = handleCYGlimits(this, 'CYG_as_tm', 'CYG_as_tr');
        if (!checkLimit) return;

        return new Promise((resolve, reject) => {
            InstagramService
                .handleLoadInstagramAccountsSearch(accName)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    handleLoadCheckYourGrowthStatistics({ commit },accId) {
        const checkLimit = handleCYGlimits(this, 'CYG_st_tm', 'CYG_st_tr');
        if (!checkLimit) return;

        return new Promise((resolve, reject) => {
            InstagramService
                .handleLoadCheckYourGrowthStatistics(accId)
                .then(response => {
                    commit('SET_GROWTH_STATISTICS', response.data)
                    return resolve(response.data)
                })
                .catch(error => reject(error.message))
        })
    },
};

const getters = {
    getGrowthAcc: state => state.checkGrowthAcc,
    getGrowthStatistics: state => state.growthStatistics,
};

export default  {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
