import autoPromotionIcon from '@/assets/images/dropDownMenu/autoProm.svg';
import autoboostIcon from '@/assets/images/product/autoboost/autoboost_icon.svg';
import directMessagesIcon from '@/assets/images/dropDownMenu/direct.svg';
import schedulePostingIcon from '@/assets/images/dropDownMenu/schedule.svg';
import commentTrackerIcon from '@/assets/images/dropDownMenu/comment.svg';
import hashtagGeneratorIcon from '@/assets/images/dropDownMenu/hashtag.svg';
import influencersDiscoveryIcon from '@/assets/images/dropDownMenu/search.svg';

import UserService from '@/services/api/user.service';

const initialState = {
    allowedProducts: [],
    redirectPage: 'MembershipPage',
    productsTour: {
        autoboost: {isPaused: false,},
        autopromotion: {isPaused: false,},
        commenttracker: {isPaused: false,},
        directmessages: {isPaused: false,},
        hashtaggenerator: {isPaused: false,},
        scheduleposting: {isPaused: false,},
        influencersdiscovery: {isPaused: true},
    },
    serviceProducts: {
        autopromotion: {
            route: {
                path: '/autopromotion',
                name: 'AutopromotionPage',
            },
            label: 'AUTO PROMOTION',
            icon: autoPromotionIcon,
            store: 'autopromotion'
        },
        autoboost: {
            route: {
                path: '/autoboost',
                name: 'AutoBoostPage',
            },
            label: 'Auto Boost',
            icon: autoboostIcon,
            store: ''
        },
        directmessages: {
            route: {

                path: '/direct-messages',
                name: 'DirectMessagesPage',
            },
            label: 'DIRECT MESSAGING',
            icon: directMessagesIcon,
            store: 'direct'
        },
        scheduleposting: {
            route: {
                path: '/schedule-posting',
                name: 'SchedulePostingPage',
            },
            label: 'SCHEDULE POSTING',
            icon: schedulePostingIcon,
            store: 'posting'
        },
        commenttracker: {
            route: {
                path: '/comment-tracker',
                name: 'CommentTrackerPage',
            },
            label: 'COMMENT TRACKER',
            icon: commentTrackerIcon,
            store: 'comment'
        },
        hashtaggenerator: {
            route: {
                path: '/hashtag-generator',
                name: 'HashtagGeneratorPage',
            },
            label: 'HASHTAG GENERATOR',
            icon: hashtagGeneratorIcon,
            store: 'hashtag'
        },
        influencersdiscovery: {
            route: {
                path: '/influencers-discovery',
                name: 'InfluencerDiscoveryPage',
            },
            label: 'INFLUENCERS DISCOVERY',
            icon: influencersDiscoveryIcon,
            store: 'influencers'
        },
    },
    storeModules: {
        hashtaggenerator: 'hashtag',
        directmessages: 'direct',
        scheduleposting: 'posting',
        commenttracker: 'comment',
        influencersdiscovery: 'influencers'
    }
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
    idb: {
        status: '',
        stateMessage: '',
    }
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)))
    },
    SET_PRODUCTS_TOUR(state, payload) {
        const products = Object.keys(state.productsTour);

        for (const product of products) {
            Object.assign(state.productsTour[product], payload[product])
        }
    },
    CHANGE_PRODUCT_TOUR_STATE(state, {product, status}) {
        state.productsTour[product].isPaused = status;
    },
    UPDATE_PRODUCT_TOUR(state, payload) {
        Object.assign(state.productsTour, payload);
    },
    SET_ALLOWED_PRODUCTS(state, payload) {
        const productsList = {
            autopromotion: 'AutoPromotionPage',
            directmessages: 'DirectMessagesPage',
            scheduleposting: 'SchedulePostingPage',
            commenttracker: 'CommentTrackerPage',
            hashtaggenerator: 'HashtagGeneratorPage',
        };

        if (payload) {
            const allowedAccessPages = Object.keys(productsList)
                .map(p => {
                    const checkSlots = payload[p].slots > 0;
                    const checkActualDate = new Date(payload.paidUntil) > new Date();

                    return checkSlots && checkActualDate ? productsList[p] : false
                })
                .filter(p => !!p);

            state.redirectPage = allowedAccessPages.length ? allowedAccessPages[0] : 'MembershipPage';
            state.allowedProducts = [...allowedAccessPages, 'InfluencerDiscoveryPage','AutoBoostPage'];
        } else state.redirectPage = 'MembershipPage';
    },
    SET_IDB(state, payload) {
        Object.assign(state.idb, payload);
    },
    UPDATE_PRODUCT_TOUR_STEP(state, payload ) {
        Object.assign(state.productsTour, payload)
    },
};

const actions = {
    clearAppStorage({commit}) {
        this._vm.$eventHub.$emit('pre-logout-loading');

        commit('SET_LOGOUT_STATE');
        commit('autoliking/SET_LOGOUT_STATE', {}, {root: true});
        commit('autopromotion/SET_LOGOUT_STATE', {}, {root: true});
        commit('billing/SET_LOGOUT_STATE', {}, {root: true});
        commit('comment/SET_LOGOUT_STATE', {}, {root: true});
        commit('direct/SET_LOGOUT_STATE', {}, {root: true});
        commit('hashtag/SET_LOGOUT_STATE', {}, {root: true});
        commit('instagram/SET_LOGOUT_STATE', {}, {root: true});
        commit('posting/SET_LOGOUT_STATE', {}, {root: true});
        commit('user/SET_LOGOUT_STATE', {}, {root: true});
        commit('influencers/SET_LOGOUT_STATE', {}, { root: true });
    },
    // eslint-disable-next-line no-unused-vars
    getProductTourState({commit}) {
        return new Promise((resolve, reject) => {
            UserService.getTourState()
                .then((response) => {
                    commit('UPDATE_PRODUCT_TOUR', response.data.stepTour);

                    return resolve(response)
                })
                .catch(error => reject(error));
        })
    },
    changeProductTourState({commit}, payload) {
        return new Promise((resolve, reject) => {
            UserService
                .updateTourState(payload)
                .then(response => {
                    commit('UPDATE_PRODUCT_TOUR', response.data.stepTour);

                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    changeProductTourStep({commit}, payload){
        commit('UPDATE_PRODUCT_TOUR_STEP', payload);
    },
    skipProductTourState({commit}, payload) {
        commit('UPDATE_PRODUCT_TOUR', payload);
    }
};

const getters = {
    getRedirectPage: state => state.redirectPage,
    getProductAccess: state => state.allowedProducts,
    getStoreModules: state => {
        const storeModulesByProductName = {};

        for (const product of Object.keys(state.serviceProducts)) {
            Object.assign(
                storeModulesByProductName,
                { [product]: state.serviceProducts[product].store }
            );
        }

        return storeModulesByProductName;
    },
    getProductRoutes: state => {
        const productsRoutes = {};

        for (const product of Object.keys(state.serviceProducts)) {
            const { route } = state.serviceProducts[product];

            Object.assign(productsRoutes, { [product]: { ...route } });
        }

        return productsRoutes;
    },
    getProductsInfo: state => Object
        .keys(state.serviceProducts)
        .map(name => ({
            name,
            icon: state.serviceProducts[name].icon,
            label: state.serviceProducts[name].label,
        })),
    getStepTourState: state => state.productsTour
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
