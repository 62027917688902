import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';
import idb from '@/store/idb';
/**
 * Vuex modules
 */
import AppModule from './modules/app.module';
import UserModule from './modules/user.module';
import BillingModule from './modules/billing.module';
import InstagramModule from './modules/instagram.module';
import AutopromotionModule from './modules/autopromotion.module';
import HashtagModule from './modules/hashtag.module';
import CommentModule from './modules/comment.module';
import PostingModule from './modules/posting.module';
import DirectModule from './modules/direct.module';
import LandingModule from './modules/landing.module';
import InfluencersModule from './modules/influencers.module'


const vuexLocal = new VuexPersistence({
    key: 'insocial-app',
    asyncStorage: false,
    storage: window.localStorage,
    modules: [
        'app',
        'user',
        'billing',
        'instagram',
        'autoboost',
        'autopromotion',
        'hashtag',
        'comment',
        'posting',
        'landing',
        'influencers'
    ],
});

const vuexIndexedDb = new VuexPersistence({
    key: 'direct-messages',
    asyncStorage: true,
    storage: idb,
    modules: ['direct'],
    reducer: state => ({
        tags: state.direct.tags,
        accounts: state.direct.accounts,
        selectedUser: state.direct.selectedUser,
        selectedUserBio: state.direct.selectedUserBiography,
        requestInbox: state.direct.requestInbox,
    })
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app: AppModule,
        user: UserModule,
        billing: BillingModule,
        instagram: InstagramModule,
        autopromotion: AutopromotionModule,
        hashtag: HashtagModule,
        comment: CommentModule,
        posting: PostingModule,
        direct: DirectModule,
        landing: LandingModule,
        influencers: InfluencersModule
    },
    plugins: [vuexLocal.plugin, vuexIndexedDb.plugin],
    mutations: {
        RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    },
});
