import vSelect from 'vue-select';
import VueApexCharts from 'vue-apexcharts';
import VueSweetalert2 from 'vue-sweetalert2';
import { HalfCircleSpinner } from 'epic-spinners';
import CountDown from 'keep-countdown';
import Loading from 'vue-loading-overlay';
import AppLink from '@/components/core/AppLink';
import AppLoading from '@/components/core/AppLoading';
import WarningIcon from '@/components/core/WarningIcon';
import AppResultPlug from '@/components/core/AppResultPlug';
import ConfirmDialog from '@/components/app/core/ConfirmDialog';
import { templates } from 'vuelidate-error-extractor';
import vmodal from 'vue-js-modal';
import WaveSurferVue from "wavesurfer.js-vue";
import Multiselect from 'vue-multiselect';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
import OtpInput from 'otp-input-vue2';

const componentsGlobal = (Vue) => {
    Vue.component('otp-input', OtpInput);
    Vue.component('v-select', vSelect);
    Vue.component('apexchart', VueApexCharts);
    Vue.component('half-circle-spinner', HalfCircleSpinner);
    Vue.component('form-group', templates.singleErrorExtractor.foundation6);
    Vue.component('count-down', CountDown);
    Vue.component('app-link', AppLink);
    Vue.component('loading', Loading);
    Vue.component('app-loading', AppLoading);
    Vue.component('app-result-plug', AppResultPlug);
    Vue.component('warning-icon', WarningIcon);
    Vue.component('confirm-dialog', ConfirmDialog);
    Vue.use(WaveSurferVue);
    Vue.use(vmodal, { dialog: true });
    Vue.use(VueApexCharts);
    Vue.use(VueSweetalert2);
    Vue.component('multiselect', Multiselect);
    Vue.use(TawkMessengerVue, {
        propertyId : process.env.VUE_APP_PROPERTY_ID,
        widgetId : process.env.VUE_APP_WIDGET_ID,
    });
};

export default componentsGlobal;
