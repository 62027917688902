<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="icon-warning">
    <path d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M24,31 C22.8954305,31 22,31.8954305 22,33 C22,34.1045695 22.8954305,35 24,35 C25.1045695,35 26,34.1045695 26,33 C26,31.8954305 25.1045695,31 24,31 Z M24,14 C23.1715729,14 22.5,14.6715729 22.5,15.5 L22.5,15.5 L22.5,27.5 C22.5,28.3284271 23.1715729,29 24,29 C24.8284271,29 25.5,28.3284271 25.5,27.5 L25.5,27.5 L25.5,15.5 C25.5,14.6715729 24.8284271,14 24,14 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "WarningIcon"
}
</script>

<style lang="scss" scoped>
.icon-warning {
  fill: #e6a23c;
  width: 64px;
  height: 64px;
}
</style>