const nodeEnv = process.env.NODE_ENV;
const appMode = process.env.VUE_APP_MODE;

const appNameByEnv = {
    development: {
        stage: 'insocial_localhost',
        development: 'insocial_localhost',
        production: 'insocial_app'
    },
    production: {
        stage: 'insocial_stage',
        development: 'insocial_localhost',
        production: 'insocial_app'
    }
};
const appDomainsByEnv = {
    development: {
        stage: ['localhost:8080', 'app.localhost:8080'],
        development: ['localhost:8080', 'app.localhost:8080'],
        production: ['insocial.io', 'app.insocial.io']
    },
    production: {
        stage: ['stage.insocial.io', 'stage-app.insocial.io'],
        development: ['localhost:8080', 'app.localhost:8080'],
        production: ['insocial.io', 'app.insocial.io']
    }
}

export default {
    debounce: {
        listenTo: 'keyup',
        defaultTime: '750ms',
        fireOnEmpty: false
    },
    bugSnag: {
        key: process.env.VUE_APP_BUGSNAG_KEY,
    },
    gAuth: {
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'profile email',
        prompt: 'select_account'
    },
    gtag: {
        config: {
            id: process.env.VUE_APP_GTAG
        },
        linker: {
            domains: appDomainsByEnv?.[nodeEnv]?.[appMode] ?? 'inSOCIAL Dashboard'
        },
        appName: appNameByEnv?.[nodeEnv]?.[appMode],
        pageTrackerEnabled: true,
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_path: to.path,
                page_name: to.name,
                page_title: to.meta.title,
                page_origin: window.location.origin
            }
        }
    },
    scrollTo: {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -75,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    },
    lazyLoad: {
        preLoad: 1.3,
        attempt: 1,
        listenEvents: [ 'scroll' ]
    },
    toastMessages: {
        timer: 5000,
        toast: true,
        position: 'top-right',
        showConfirmButton: false
    },
    paypal: {
        buttonsStyle: { shape: 'rect', color: 'blue', layout: 'horizontal', label: 'paypal', tagline: false },
        scriptConfig: {
            'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
            'commit': true,
            'components': 'buttons',
            'currency': 'USD',
            'enable-funding': 'card,paypal',
            'disable-funding': 'credit,paylater,venmo',
            'intent': 'capture',
            'vault': false
        }
    },
    square: {
        appId: process.env.VUE_APP_SQUARE_APP_ID,
        locationId: process.env.VUE_APP_SQUARE_LOCATION_ID,
        scriptUrl: process.env.VUE_APP_SQUARE_LIBRARY_URL
    },
    apiMode: process.env.APP_API_MODE
}
