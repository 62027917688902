import {
    email,
    sameAs,
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    ipAddress,
    numeric
} from 'vuelidate/lib/validators';

export default {
    firstNameRule: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(15),
        nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
        twoCharactersInRow: val => !/(.)\1{2}/u.test(val)
    },
    lastNameRule: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(15),
        nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
        twoCharactersInRow: val => !/(.)\1{2}/u.test(val)
        // nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]{1,15}$/gu.test(val)
    },
    emailRule: { required, email },
    phoneRule: { required },
    passwordRule: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(35),
        validPassword: (val) => /^(?=.*\d)(?=.*[a-z][^\\s])(?=.*[A-Z]|[!@#$%^&*])(?!.*[\s]).{8,35}$/g.test(val),
    },
    confirmPasswordRule: {
        required,
        sameAsPassword: sameAs('password')
    },
    instagramAccountUsername: {
        required
    },
    instagramAccountPassword: {
        required
    },
    proxyHost: {
        required,
        hostFormat: ipAddress
    },
    proxyPort: {
        required,
        numeric,
        minValue: minValue(80),
        maxValue: maxValue(65535)
    },
    proxyLogin: { required },
    proxyPass: { required },
    messageRule: {
        required,
        minLength: minLength(15),
        maxLength: maxLength(300),
    },
    directTagLabel: {
        required,
        maxLength: maxLength(10),
    },
    autoboostCreditsRules: {
        required,
        numeric,
        maxLength: maxLength(4),
        minValue: minValue(10),
        maxValue: maxValue(2000)
    },
    influencersdiscoveryCreditsRules: {
        required,
        numeric,
        maxLength: maxLength(5),
        minValue: minValue(250),
        maxValue: maxValue(25000)
    },
    creditsRulesInput: {
        required,
        numeric,
        maxLength: maxLength(5),
        minValue: minValue(250),
        maxValue: maxValue(25000)
    },
    accountType: {
        required,
    },
    promo: {
        required,
        maxLength: maxLength(6),
        minLength: minLength(6)
    },
    countryRule: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
        nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
        twoCharactersInRow: val => !/(.)\1{2}/u.test(val)
    },
    cityRule: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
        nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
        twoCharactersInRow: val => !/(.)\1{2}/u.test(val)
    },
    streetRule: {
        maxLength: maxLength(50),
        minLength: minLength(2),
        required,
    },
    stateCodeRule: {
        required,
    },
    zipCodeRule: {
        required,
        maxLength: maxLength(10),
        zipFormat: val => /^[0-9-]+$/.test(val),
    }
}
