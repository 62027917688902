import InfluencerService from '@/services/api/influencer.service';

const initialState = {
    filters: {
        initStatus: false,
        components: {
            Gender: {gender:{},audience_gender:{}},
            'Last Post': {last_posted: {}},
            Ethnicity: {audience_race: {}}
        },
        values: null,
        payload: null,
        sortOptions: [
            {
                label: 'Followers',
                field: 'followers',
                id: false
            },
            {
                label: 'Engagements',
                field: 'engagements',
                id: false
            },
        ],

    },
    selectedSortOption: {
        field: "engagements",
        label: "Engagements",
        id: false,
    },
    influencersData: {},
    influencerProfile: null,
    reportList: null,
    report: null,
    customLists: [],
    test: '',

};


const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_CUSTOM_LISTS(state, data){
        state.customLists = [...data.lists]
    },
    ADD_CUSTOM_LISTS(state, data) {
        state.customLists.push(data.list)
    },
    SET_LOGOUT_STATE(state){
        Object.assign(state, JSON.parse(JSON.stringify(initialState)))
    },
    SET_SORT_OPTIONS(state, data){
        state.filters.sortOptions = data
    },
    SET_INITIAL_STATE(state, data) {
        if (!state.filters.values) {
            state.filters.values = {};
            state.filters.payload = {};
            state.filters.components = {
                Gender: {gender:{},audience_gender:{}},
                'Last Post': {last_posted: {}},
                Ethnicity: {audience_race: {}}
            };
        }

        Object.assign(state.filters.values, data);
        state.filters.initStatus = true;
    },
    REMOVE_SELECTED_FILTERS(state){
        state.filters.components = Object.assign({}, JSON.parse(JSON.stringify(initialState.filters.components)))
    },
    SET_FILTER_VALUES(state, data) {
        state.filters.components =  Object.assign({}, state.filters.components, data);
        // Object.assign(state.filters.components, data);
        // this._vm.$set(state.filters, "components", data)
        // if (!Object.keys(state.filters.components).length)
    },
    SET_RADIO_FILTER_VALUES(state, data){
        if(data.subtype){
            Object.assign(state.filters.components[data.filter][data.type][data.subtype], data.value)
        } else Object.assign(state.filters.components[data.filter][data.type], data.value)
    },
    SET_ADDITIONAL_FILTER_VALUES(state, data) {
        Object.assign(state.filters.components[data.filterTitle][data.groupTitle], {additional: data.value})
    },
    SET_SEARCH_RESULT(state, data) {
        state.influencersData = Object.assign({}, state.influencersData, data);
    },
    SET_UNHIDE_RESULTS(state, {data}){
        let arr = [...state.influencersData.accounts]
        state.influencersData.accounts = []
        arr.forEach((acc, idx, arr) =>{
            const unlockAccIdx = data.profiles.findIndex((profile) => profile.unhideMatch === acc.state.searchId)

            if (unlockAccIdx < 0) return

            arr[idx] = data.profiles[unlockAccIdx];
        })
        state.influencersData.accounts = arr
    },
    ADD_SEARCH_RESULT(state, data) {
        state.influencersData.accounts.push(...data.accounts)
    },
    DELETE_SELECTED_FILTER(state, data){
        if(data.name) {
            state.filters.components[data.filterTitle][data.groupTitle].forEach((filterItem, idx )=> {
                if(filterItem.name === data.name) {
                    state.filters.components[data.filterTitle][data.groupTitle].splice(idx, 1)
                }
            })
        }
        else if(data.additional){
            state.filters.components.Additional[data.additional] = false;
        }
        else if(data.range){
            if(data.groupTitle === 'engagements_rate'){
                state.filters.components[data.filterTitle][data.groupTitle].left_number.label = '';
            } else if(data.groupTitle === 'engagements') {
                state.filters.components[data.filterTitle][data.groupTitle].left_number.label = '';
                state.filters.components[data.filterTitle][data.groupTitle].right_number.label = '';
            } else {
                state.filters.components[data.filterTitle][data.groupTitle].left_number = '';
                state.filters.components[data.filterTitle][data.groupTitle].right_number = '';
            }
        }
        else if(data.deleteParam){
            // state.filters.components[data.deleteParam.filterTitle][data.deleteParam.groupTitle][data.deleteParam.idx]
            state.filters.components[data.deleteParam.filterTitle][data.deleteParam.groupTitle].splice(data.deleteParam.idx, 1)
        }
        else if(data.radio){
             // this._vm.$delete(state.filters.components[data.filterTitle].additional, data.groupTitle)
             // this._vm.$delete(state.filters.components[data.filterTitle], data.groupTitle)
            Object.keys(state.filters.components[data.filterTitle][data.groupTitle]).forEach(key => this._vm.$delete(state.filters.components[data.filterTitle][data.groupTitle], key));
        }
        else {
            this._vm.$delete(state.filters.components[data.filterTitle], data.groupTitle)
        }

        // Object.assign({},state.filters.components ,delete state.filters.components[data])
    },
    SET_INFLUENCER_PROFILE(state, data){
        state.influencerProfile = data;
    },
    SET_REPORT_LIST(state, data){
        state.reportList = data
    },
    SET_REPORT(state, data){
        state.report = data
    },
    RENAME_CUSTOM_LIST(state, data){
        state.customLists.find(list => list.id === data.id).label = data.label
    },
    UPDATE_DISCOVERY_PROFILE_RESULT(state, data){
       const responseAcc = state.influencersData.accounts.find(acc => acc.state.searchId === data.searchId)
        Object.assign(responseAcc.state, data)
    },
    REMOVE_REPORT_FROM_LIST(state, data){
        const targetListIndex = state.customLists.findIndex(list => list.id === data.listId)
        const targetProfileIndex = state.customLists[targetListIndex].profiles.findIndex(profile => profile.internalProfileId === data.influencerId[0])
        state.customLists[targetListIndex].profiles.splice(targetProfileIndex, 1)
    },
    SET_SELECTED_SORT_OPTION(state, data){
        state.selectedSortOption = data;
    },
    UPDATE_EXPORT_TYPE(state){
        state.influencerProfile.report.isExportFree = true;
    },
};
const actions = {
    deleteSelectedFilter({commit}, deleteParam){
      commit('DELETE_SELECTED_FILTER', deleteParam)
    },
    setFiltersInitialState({ commit }, payload) {
        commit('SET_INITIAL_STATE', payload);
    },
    // eslint-disable-next-line no-empty-pattern
    getLocationByQuery({}, data) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getLocation(data)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    getRelevantTopicsOptions({}, searchQuery) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getRelevantTopicsOptions(searchQuery)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    getLookaLikeOptions({}, searchQuery) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getLookaLikeOptions(searchQuery)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },
    requestSearchResult({ commit }, payload) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getSearchResult(payload)
                .then(response => {
                    payload.paging.skip ?
                        commit('ADD_SEARCH_RESULT', response.data):
                        commit('SET_SEARCH_RESULT', response.data)
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    getInfluencerProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getInfluencerProfile(payload)
                .then(response => {
                    commit('SET_INFLUENCER_PROFILE', response.data);
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    getInfluencersReportList({ commit }, searchQuery) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getInfluencersReportList(searchQuery)
                .then(response => {
                    commit('SET_REPORT_LIST', response.data);
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    getInfluencersReportFetch({ commit }, profileId) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getInfluencersReportFetch(profileId)
                .then(response => {
                    commit('SET_REPORT', response.data);
                    commit('SET_INFLUENCER_PROFILE', response.data);
                    return resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                });
        })
    },
    resetInfluencerProfile({ commit }){
        commit('SET_INFLUENCER_PROFILE', null)
    },

    getSavedLists({ commit }) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getSavedLists()
                .then(response => {
                    commit('SET_CUSTOM_LISTS', response.data);
                    return resolve(JSON.parse(JSON.stringify(response.data.lists)));
                })
                .catch(error => reject(error));
        })
    },
    getOpenedInfluencersList() {
        return new Promise((resolve, reject) => {
            InfluencerService
                .getOpenedInfluencersList()
                .then(response => {
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    createInfluencersList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .createInfluencersList(payload)
                .then(response => {
                    commit('ADD_CUSTOM_LISTS', response.data);
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    updateInfluencersList({}, data) {
        return new Promise((resolve, reject) => {
            let payload = {influencerId: '', label: ''}
            if (data.influencerId) payload.influencerId = data.influencerId;
            if (data.label) payload.label = data.label;
            InfluencerService
                .updateInfluencersList(data.listId, data.action, payload)
                .then(response => {
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    addReportToList({ }, payload){
        return new Promise((resolve, reject) => {
            InfluencerService
                .addInfluencerToList(payload)
                .then( response => {
                    return resolve(response.data)
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    removeReportFromList({commit}, payload){
        return new Promise((resolve, reject) => {
            InfluencerService
                .removeInfluencerFromList(payload)
                .then( response => {
                    commit('REMOVE_REPORT_FROM_LIST', payload)
                    return resolve(response.data)
                })
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    removeInfluencersList({}, listId) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .removeInfluencersList(listId)
                .then(response => {
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    updateInfluencersListName({ commit }, payload){
        return new Promise((resolve, reject) => {
            InfluencerService
                .renameInfluencersList(payload)
                .then(response => {
                    commit('RENAME_CUSTOM_LIST', response.data.list[0]);
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    createInfluencersNewReport( { commit }, payload) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .createNewReport(payload)
                .then(response => {
                    commit('UPDATE_DISCOVERY_PROFILE_RESULT', response.data.result.state);
                    commit('billing/UPDATE_INFLUENCERS_CREDITS', response.data.creditsLeft, {root: true});
                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
    unhideNextInfluencersChunk({ commit }, payload) {
        return new Promise((resolve, reject) => {
            InfluencerService
                .unhideNextChunk(payload)
                .then( response => {
                    commit('SET_UNHIDE_RESULTS', response);
                    commit('billing/UPDATE_INFLUENCERS_CREDITS', response.data.creditsLeft, {root: true});
                    return  resolve(response.data)
                })
                .catch(error => reject(error))
        });
    },
    getReportPDF({commit}, payload){
        return new Promise((resolve, reject) => {
            InfluencerService
                .getReportPDF(payload.id)
                .then( response => {
                    if (!payload.isExportFree) {
                        commit('billing/UPDATE_INFLUENCERS_CREDITS', 'pdf', {root: true})
                        commit('UPDATE_EXPORT_TYPE')
                    }
                    return  resolve(response.data)
                })
                .catch(async(error) => {
                    const errorJson = JSON.parse(await error.text());
                    return reject(errorJson);
                })

        });
    },
};

const getters = {
    getSelectedSortOption: state => state.selectedSortOption,
    getFilterValue: state => filterName => state.filters.components[filterName],
    getFiltersInitState: state => state.filters.initStatus,
    getSelectedFilters: state => state.filters.components,
    getFiltersPayload: state => state.filters.payload,
    getInfluencersData: state => state.influencersData,
    getInfluencerProfile: state => state.influencerProfile,
    getSortOptions: state => state.filters.sortOptions,
    getCustomLists: state => state.customLists,
    getCustomList: state => id => state.customLists.find(list => list.id === id),
    getAdditionalValue: state => (filterTitle, groupTitle, idx) => {
        if(idx === 'additional'){
            if('additional' in state.filters.components[filterTitle][groupTitle]){
                return state.filters.components[filterTitle][groupTitle]
            } else return ''
        } else if(idx === 'left_number'){
            if('left_number' in state.filters.components[filterTitle][groupTitle]){
                return state.filters.components[filterTitle][groupTitle].left_number.label
        } else return ''}
        else {
            let obj = state.filters.components[filterTitle][groupTitle][idx];
            return 'additional' in obj ? obj.additional.label : '';
        }
    },
}
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
