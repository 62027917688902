import ApiClient from '@/libs/http-client';

const AutopromotionService = {
  fetchCampaign(instagramId) {
    return ApiClient.get(`/autopromotion/campaign/${ instagramId }`);
  },
  manageCampaign(instagramId, data) {
    return ApiClient.post(`/autopromotion/campaign/${ instagramId }/manage`, data)
  },
  addCampaignSource(instagramId, data) {
    return ApiClient.post(`/autopromotion/source/${ instagramId }`, data);
  },
  deleteCampaignSource(instagramId, sourceId) {
    return ApiClient.delete(`/autopromotion/source/${ sourceId }/${ instagramId }`)
  }
}

export default AutopromotionService;
