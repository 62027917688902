import Vue from "vue";
import _ from 'lodash';
import DirectService from '@/services/api/direct.service';
import InstagramService from '@/services/api/instagram.service';

const requestConfig = (commit, uploadType, opts) => {
    return {
        ...opts,
        onUploadProgress: function(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            commit('SET_UPLOAD_MEDIA_PROGRESS', { uploadType, percentCompleted });
        }
    }
};

const initialState = {
    loadingState: 'pending',
    accountLoadingState: null,
    activeSlot: {
        type: null, //slot, account,
        slotId: null // slot id or account id
    },
    actionLogs: [],
    tags: {},
    count: 0,
    accounts: {},
    requestInbox: {},
    selectedUser: {},
    selectedUserBiography: {},
    inboxLoadingState: null,
    selectedThreadId: {
        prev: '',
        current: '',
    },
    convertVideo: {
        finished: false,
        percent: '0%',
        threadId: '',
    },
    notRenderedMessages: null,
    uploadMediaProgress: 0,
    handleRequestShow: false,
    handleRequestLoader: false,
    handleRequestOnActionLoader: false,
    handleRequestMultipleSelect: false,
    handleDetailBlock: false,
    handleMediaFetch: false,
    handleOpenedModal: false,
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    SET_ACTIVE_SLOT(state, payload) {
        Object.assign(state.activeSlot, payload);
    },
    SET_LOADING_STATE(state, payload) {
        state.loadingState = payload;
    },
    SET_ACCOUNT_LOADING_STATE(state, accountId) {
        state.accountLoadingState = accountId;
    },
    UPDATE_CONTACT_IN_THREAD_LIST(state, payload) {
        state.accounts[payload.selectedInstagramId].inbox
            .sort((x, y) => x.threadId === payload.threadId ? -1 : y.threadId === payload.threadId ? 1 : 0);
    },
    UPDATE_ACCOUNT_INBOX(state, payload) {
        Vue.set(state.accounts, payload.instagramId, payload.data);
    },
    SET_UPLOAD_MEDIA_PROGRESS(state, { percentCompleted }){
        state.uploadMediaProgress = percentCompleted;
    },
    SET_ACCOUNT_INBOX(state, payload) {

        payload.data.inbox.forEach(thread => {
            thread.isNewThread = false;
            thread.isFirstOpen = true;
            thread.isFakeThread = false;
        });

        if (_.isUndefined(state.accounts[payload.instagramId])) {
            Vue.set(state.accounts, payload.instagramId, payload.data);
        } else {
            Object.assign(
                state.accounts[payload.instagramId],
                {
                    inbox: state.accounts[payload.instagramId].inbox.concat(payload.data.inbox),
                    state: payload.data.state
                }
            );

        }
    },
    // eslint-disable-next-line no-unused-vars
    DELETE_TAG(state, {threadId, index}) {
        let filteredArray = state.tags[threadId].filter((tag, tagIndex) => {
            return tagIndex !== index;
        });

        Vue.set(state.tags, threadId, filteredArray);
    },
    UPDATE_TAG(state, {data, tag, tagId}) {
        data.id = tagId;
        Vue.set(state.tags[tag.tag.threadId], tag.index, data);
    },
    ADD_NEW_TAG(state, tagParams) {
        if (state.tags[tagParams.threadId]) state.tags[tagParams.threadId].push(tagParams);
        else Vue.set(state.tags, tagParams.threadId, [tagParams]);
    },
    SET_ACCOUNTS_TAGS(state, payload) {
        state.tags = payload.tags.reduce((target, key) => {
            if (target[key.threadId]) target[key.threadId].push(key);
            else target[key.threadId] = [key];
            return target;
        }, {});
    },
    SET_ACCOUNTS(state, payload) {
        Object.assign(state.accounts, payload)
    },
    // eslint-disable-next-line no-unused-vars
    UPDATE_SELECTED_USER_THREAD(state, {data, threadId, instagramId}) {
        if (state.selectedUser.threadId !== threadId) return;

        let payloadState = JSON.parse(data.state);

        if (state.selectedUser) {
            Object.assign(state.selectedUser, {items: [...state.selectedUser.items, ...data.inboxThread]});

            Object.assign(state.selectedUser, {
                moreAvailable: payloadState.moreAvailable ?? true,
                cursor: payloadState.cursor
            });
        }

        for (const thread of state.accounts[instagramId].inbox.entries()) {
            if (thread.threadId === threadId) {
                Object.assign(state.accounts[instagramId], {
                    inbox: state.selectedUser.items,
                    state: data.state,
                });
            }
        }


    },
    MESSAGE_UPDATE_ON_ACTION(state, data) {
        let {payload, instagramId} = data;

        payload.isSentByViewer = Number(payload.userId) === Number(instagramId);

        if (state.selectedUser.threadId === payload.threadId) {
            let index = state.selectedUser.items.findIndex(el => el.itemId === payload.itemId);
            Vue.set(state.selectedUser.items, index, payload);
        } else {
            let threadIndex = state.accounts[instagramId].inbox.findIndex(el => el.threadId === payload.threadId);
            let messageIndex = state.accounts[instagramId].inbox[threadIndex].items.findIndex(el => el.itemId === payload.itemId);

            Vue.set(state.accounts[instagramId].inbox[threadIndex].items, messageIndex, payload);
        }
    },
    SET_SELECTED_USER(state, payload) {
        state.selectedUser = payload;
    },
    SET_SELECTED_USER_BIO(state, payload) {
        state.selectedUserBiography = payload;
    },
    // eslint-disable-next-line no-unused-vars
    ADD_NEW_MESSAGE(state, { result, threadId, campaignId}) {
        result.isSentByViewer = true;

        if (state.selectedUser.threadId === threadId) {
            return state.selectedUser.items.unshift(result);
        }
    },
    ADD_HIDDEN_THREAD(state, {inbox, instagramId}) {
        state.accounts[instagramId].inbox.unshift(inbox[0]);
    },
    // eslint-disable-next-line no-unused-vars
    SET_NEW_MESSAGE(state, {payload, instagramId}) {
        let threadIndx = state.accounts[instagramId].inbox.findIndex(thread => thread.threadId === payload.threadId);

        if (threadIndx === -1) this.dispatch('direct/loadDirectInbox', {
            instagramId,
            state: null,
            update: false,
            threadNull: true
        })

        if (state.selectedUser.threadId === payload.threadId && `${instagramId}` === `${payload.userId}`) {
            return state.selectedUser.items.forEach(message => {
                if (message.itemId === payload.itemId) return null;
            });
        }

        if (`${payload.userId}` === instagramId) payload.isSentByViewer = true;

        if (state.selectedUser.threadId === payload.threadId) {
            state.selectedUser.items.unshift(payload)
            Vue.set(state.selectedUser, 'items', state.selectedUser.items);
        } else state.accounts[instagramId].inbox.map((thread, index) => {
            if (thread.threadId === payload.threadId) {
                state.accounts[instagramId].inbox[index].items.unshift(payload);

                Vue.set(state.accounts[instagramId].inbox[index], 'items', state.accounts[instagramId].inbox[index].items);

            }
        })

    },
    SET_SELECTED_THREAD_ID(state, payload) {
        if (state.selectedThreadId.current) {
            state.selectedThreadId.prev = state.selectedThreadId.current;
            state.selectedThreadId.current = payload;
        } else if (!state.selectedThreadId.current) {
            state.selectedThreadId.prev = payload;
            state.selectedThreadId.current = payload;
        }
    },
    SET_NEW_REQUEST_INBOX(state, payload) {
        if (_.isUndefined(state.requestInbox[payload.instagramId])) {
            Vue.set(state.requestInbox, payload.instagramId, payload.data);
        } else {
            Vue.set(state.requestInbox[payload.instagramId], 'pending', [...state.requestInbox[payload.instagramId].pending, ...payload.data.pending])
            Vue.set(state.requestInbox[payload.instagramId], 'state', payload.data.state)
        }
    },
    SET_REQUEST_SHOW(state, payload) {
        Vue.set(state, 'handleRequestShow', payload);
    },
    SET_REQUEST_ACTION_LOADER(state, payload) {
        state.handleRequestOnActionLoader = payload;
    },
    SET_REQUEST_LOADER(state, payload) {
        Vue.set(state, 'handleRequestLoader', payload);
    },
    SET_DETAIL_BLOCK(state, payload) {
        Vue.set(state, 'handleDetailBlock', payload);
    },
    DECLINE_REQUEST(state, payload) {
        const {instagramId, threadId, threadIds, type} = payload;

        state.selectedThreadId = {};

        switch (type) {
            case 'single':
                state.requestInbox[instagramId].pending = state.requestInbox[instagramId].pending.filter(thread => thread.threadId !== threadId);
                state.selectedUser = {};
                // state.handleRequestShow = false;
                state.selectedUserBiography = {};
                break;
            case 'all':
                state.requestInbox[instagramId].pending = [];
                break;
            case 'multiple':
                state.requestInbox[instagramId].pending = state.requestInbox[instagramId].pending.reduce((acc, thread) => {
                    if (!threadIds.includes(thread.threadId)) acc.push(thread);
                    return acc;
                }, [])
                break;
        }
    },
    APPROVE_REQUEST(state, payload) {
        const {instagramId, selectedUser, threadIds, type} = payload;

        let inbox = state.accounts[instagramId].inbox;
        let requestInbox = state.requestInbox[instagramId].pending;

        let requestThreads = null;

        switch (type) {
            case 'single':
                state.accounts[instagramId].inbox.unshift(selectedUser);
                break;
            case 'all':
                state.accounts[instagramId].inbox = [...requestInbox, ...inbox];
                break;
            case 'multiple':
                requestThreads = threadIds.map(threadId => state.requestInbox[instagramId].pending.filter(request => request.threadId === threadId));

                state.accounts[instagramId].inbox = [...requestThreads.flat(), ...state.accounts[instagramId].inbox];
                break;
        }
    },
    SET_HANDLER_SELECT_CONTACT_MULTIPLE(state, payload) {
        state.handleRequestMultipleSelect = payload;
    },
    UNSEND_THREAD_ITEM(state, { itemId }) {
        state.selectedUser.items.forEach( (item,itemIds) => {
            if (item.itemId === itemId) return state.selectedUser.items.splice(itemIds, 1);
        })
    },
    LEAVE_FROM_GROUP_THREAD(state, {instagramId, threadId}) {
        for (const [idx, thread] of state.accounts[instagramId].inbox.entries()) {
            if (thread.threadId === threadId) state.accounts[instagramId].inbox.splice(idx, 1);
        }

        state.selectedThreadId = {};
        state.selectedUser = {};
        state.handleDetailBlock = false;
    },
    CREATE_THREAD(state, {instagramId, data}) {
        let threadIndex = null;

        data.result.isNewThread = true;

        state.accounts[instagramId].inbox.map((thread, idx) => `${thread.threadId}` === `${data.result.threadId}` ? threadIndex = idx : null);

        data.result.timestamp = Math.floor(Date.now());

        if (!Number.isInteger(threadIndex)) {
            state.accounts[instagramId].inbox.unshift(data.result);
            state.selectedUser = data.result;
        } else {
            let userThread = state.accounts[instagramId].inbox[threadIndex];

            state.accounts[instagramId].inbox.splice(threadIndex, 1);

            state.accounts[instagramId].inbox.unshift(userThread);
        }
    },
    HIDE_THREAD(state, {instagramId, threadId}) {
        state.accounts[instagramId].inbox = state.accounts[instagramId].inbox.filter(thread => thread.threadId !== threadId);
        state.selectedThreadId = {};
        state.selectedUser = {};
        state.handleDetailBlock = false;
    },
    UPDATE_THREAD_ON_ACTION(state, {campaignId, message}) {
        if (message.meta.op === 'replace') {
            let threadIdx = state.accounts[campaignId].inbox.findIndex(thread => thread.threadId === message.update.threadId);
            delete message.update.items;
            Object.assign(state.accounts[campaignId].inbox[threadIdx], message.update);
        }
    },
    HANDLE_MEDIA_FETCH(state, payload) {
        state.handleMediaFetch = payload;
    },
    SET_ACTION_LOGS(state, actionLog) {
        state.actionLogs.push(actionLog);
    },
    RESET_ACTION_LOGS(state) {
        state.actionLogs = [];
    },
    SET_CONVERT_VIDEO(state, payload){
        Object.assign(state.convertVideo, payload);
    },
    UPDATE_THREAD_SCROLL_POS(state, { instagramId, scrollPos, threadId }){
        const idx = state.accounts[instagramId].inbox.findIndex(thread => thread.threadId === threadId);

        Object.assign(state.accounts[instagramId].inbox[idx], { scrollPos });
    },
    UPDATE_FIRST_OPEN_THREAD(state, { instagramId, threadIdx, payload }){
        state.accounts[instagramId].inbox[threadIdx].isFirstOpen = payload;
    },
    SET_FAKE_CHAT(state, { instagramId, thread }){
        state.accounts[instagramId].inbox.unshift(thread);
    },
    SET_NOT_RENDERED_MESSAGES(state, payload){
        if (!state.notRenderedMessages) state.notRenderedMessages = new Set([payload]);
        else state.notRenderedMessages.add(payload);
    },
    SET_HANDLE_SHOW_MODAL(state, payload){
        state.handleOpenedModal = payload
    },
    DELETE_INSOCIAL_THREAD(state, instagramId ){
        const insocialIdx = state.accounts[instagramId].inbox.findIndex( thread => thread.threadId === 'insocial');

        if (insocialIdx !== -1) {
            state.accounts[instagramId].inbox.splice(insocialIdx, 1);
        }

        if (state.selectedUser.threadId === 'insocial') state.selectedUser = {};
    },
};

const actions = {
    setProductActiveSlot({ commit }, payload) {
        commit('SET_ACTIVE_SLOT', payload);

        return Promise.resolve();
    },
    setProductLoadingState({ commit }, data) {
        commit('SET_LOADING_STATE', data);
    },
    setThreadFromInfluencerPage({commit}, payload){
        commit('SET_THREAD_FROM_INFLUENCER_PAGE', payload);
    },
    deleteInsocialThread({commit}, { instagramId }){
        commit('DELETE_INSOCIAL_THREAD', instagramId);
    },
    setFakeThread({commit}, payload){
        commit('SET_FAKE_CHAT', payload);
    },
    convertVideo({commit}, payload){
        commit('SET_CONVERT_VIDEO', payload.payload);
    },
    setDetailBLock({commit}, payload) {
        commit('SET_DETAIL_BLOCK', payload);
    },
    setRequestLoader({commit}, payload) {
        commit('SET_REQUEST_LOADER', payload);
    },
    setRequestShow({commit}, payload) {
        commit('SET_REQUEST_SHOW', payload);
    },
    setNewRequest({commit}, payload) {
        commit('SET_NEW_REQUEST_INBOX', payload);
    },
    setSelectedThreadId({commit}, payload) {
        commit('SET_SELECTED_THREAD_ID', payload);
    },
    // eslint-disable-next-line no-unused-vars
    getRealtimeSubAction({commit}, data) {
        console.log('RealtimeSub ===>', data)
    },
    // eslint-disable-next-line no-unused-vars
    getDirectAction({commit}, data) {
        console.log('Direct ===>', data)
    },
    // eslint-disable-next-line no-unused-vars
    getThreadUpdateAction({commit}, {payload}) {
        commit('UPDATE_THREAD_ON_ACTION', payload)
    },
    // eslint-disable-next-line no-unused-vars
    getReceiveThreadAction({commit}, data) {
        console.log('RECEIVE ===>', data)
    },
    // eslint-disable-next-line no-unused-vars
    setReceivedMessageSockets({commit, rootState}, data) {

        let {payload} = data;

        if (payload.op === 'replace') {
            // commit('MESSAGE_UPDATE_ON_ACTION', data)
        } else {
            commit('SET_NEW_MESSAGE', data);
        }

    },
    // eslint-disable-next-line no-empty-pattern
    requestThreadAction({}, {instagramId, threadId, action}) {
        return new Promise((resolve, reject) => {
            DirectService
                .requestThreadService(instagramId, threadId, action)
                .then(({data}) => {
                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    requestApproveMultipleThread({commit}, {instagramId, payload}) {
        return new Promise((resolve, reject) => {
            DirectService
                .requestApproveMultipleThread(instagramId, payload)
                .then(({data}) => {
                    commit('APPROVE_REQUEST', {instagramId, threadIds: payload.threadIds, type: 'multiple'})

                    commit('DECLINE_REQUEST', {instagramId, threadIds: payload.threadIds, type: 'multiple'});
                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-empty-pattern
    requestDeclineAllThread({}, {instagramId}) {
        return new Promise((resolve, reject) => {
            DirectService
                .requestDeclineAllThread(instagramId)
                .then(({data}) => {

                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-empty-pattern
    newMessageSearch({}, {instagramId, data}) {
        return new Promise((resolve, reject) => {
            InstagramService
                .topSearchRequest(instagramId, data)
                .then(({data}) => {
                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    loadRequestInbox({commit}, {instagramId, state}) {
        return new Promise((resolve, reject) => {
            DirectService
                .loadRequestInbox(instagramId, state)
                .then(({data}) => {
                    commit('SET_NEW_REQUEST_INBOX', {instagramId, data});

                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    loadDirectInbox({commit}, {instagramId, state, update, threadNull}) {
        return new Promise((resolve, reject) => {
            DirectService
                .loadDirectInbox(instagramId, state)
                .then(({data}) => {
                    let inbox = data.inbox;
                    if (threadNull) return commit('ADD_HIDDEN_THREAD', {inbox, instagramId});

                    if (update) commit("UPDATE_ACCOUNT_INBOX", {instagramId, data});
                    else commit('SET_ACCOUNT_INBOX', {instagramId, data});

                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    updateThreadList({state}, payload) {
        return new Promise((resolve, reject) => {
            for (const [idx, el] of state.accounts[payload.selectedInstagramId].inbox.entries()) {
                if (el.threadId === payload.threadId || idx !== 0) return resolve();
            }
            return reject('');
        });
    },
    // eslint-disable-next-line no-unused-vars
    editTheadTitle({commit}, {instagramId, threadId, data}) {
        return new Promise((resolve, reject) => {
            DirectService
                .editTheadTitle(instagramId, threadId, data)
                // eslint-disable-next-line no-unused-vars
                .then(({data}) => {

                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-empty-pattern
    updateDirectThreadBeforeShow({}, { instagramId, threadId, oldestCursor, signal }) {

        const config = {
            signal
        };

        return new Promise((resolve, reject) => {
            DirectService
                .loadThreadInbox(instagramId, threadId, oldestCursor, config)
                .then(({data}) => {

                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    loadDirectThread({commit}, { instagramId, threadId, oldestCursor, signal }) {

        const config = {
            signal
        };

        return new Promise((resolve, reject) => {
            DirectService
                .loadThreadInbox(instagramId, threadId, oldestCursor, config)
                .then(({data}) => {
                    commit('UPDATE_SELECTED_USER_THREAD', { data, threadId, instagramId});
                    return resolve(data.state);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    hideThread({commit}, {instagramId, threadId}) {
        return new Promise((resolve, reject) => {
            DirectService
                .hideThread(instagramId, threadId)
                .then(({data}) => {
                    commit('HIDE_THREAD', {instagramId, threadId});
                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    createThread({commit}, {instagramId, payload}) {
        return new Promise((resolve, reject) => {
            DirectService
                .createThread(instagramId, payload)
                .then((response) => {
                    let {data} = response;
                    commit('CREATE_THREAD', {instagramId, data});
                    return resolve(response);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    leaveFromGroupThread({commit}, {instagramId, threadId}) {
        return new Promise((resolve, reject) => {
            DirectService
                .leaveFromGroupThread(instagramId, threadId)
                .then(({data}) => {
                    commit('LEAVE_FROM_GROUP_THREAD', {instagramId, threadId});
                    return resolve(data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    addUserIntoGroupThread({commit}, {instagramId, threadId, payload}) {
        return new Promise((resolve, reject) => {
            DirectService
                .addUserIntoGroupThread(instagramId, threadId, payload)
                .then((response) => {
                    // commit('ADD_USERS_TO_GROUP_THREAD', data);
                    return resolve(response);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    unsendDirectThreadMessage({commit}, {instagramId, threadId, itemId, itemIdx}) {
        return new Promise((resolve, reject) => {
            DirectService
                .unsendDirectThreadMessage(instagramId, threadId, itemId)
                .then(response => {
                    // eslint-disable-next-line no-unused-vars
                    const {result, message} = response.data;
                    commit('UNSEND_THREAD_ITEM', { itemId });

                    return resolve(message);
                })
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    markedItemSeen({}, {instagramId, threadId, threadItemId, signal}) {

        const config = {
            signal
        };

        return new Promise((resolve, reject) => {
            DirectService
                .markedItemSeen(instagramId, threadId, threadItemId, config)
                .then(() => {
                    return resolve();
                })
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    sendDirectThreadMessage({commit}, {msgType, campaignId, threadId, data, signal,}) {
        const config = msgType === 'text' ? {} : requestConfig(commit, 'schedule', {
            headers: {'Content-Disposition': `form-data; name="${msgType}";`},
            signal
        });

        return new Promise((resolve, reject) => {
            DirectService
                .sendDirectThreadMessage(msgType, campaignId, threadId, data, config)
                .then(response => {
                    const { result, message} = response.data;

                    commit('ADD_NEW_MESSAGE', { result, threadId, campaignId});

                    return resolve(message);
                })
                .catch(error => reject(error.message));
        })
    },
    sendDirectThreadMessageLink({commit}, {campaignId, threadId, data}) {
        return new Promise((resolve, reject) => {
            DirectService
                .sendDirectThreadMessageLink(campaignId, threadId, data)
                .then(response => {
                    const {result, message} = response.data;

                    commit('ADD_NEW_MESSAGE', { result, threadId });

                    return resolve(message);
                })
                .catch(error => reject(error.message));
        })
    },

    // eslint-disable-next-line no-empty-pattern
    getInstagramProfileDescription({commit}, {instagramId, threadId, signal}) {
        const config = {
            signal
        };

        return new Promise((resolve, reject) => {
            DirectService
                .getInstagramProfileDescription(instagramId, threadId, config)
                .then(response => {
                    commit("SET_SELECTED_USER_BIO", response.data.account)

                    return resolve(response.data);
                })
                .catch(error => {
                    commit("SET_SELECTED_USER_BIO", { error: true, username: 'Instagram User'})
                    reject(error)
                })
        })
    },
    loadAccountTags({commit}, payload) {
        return new Promise((resolve, reject) => {
            DirectService
                .loadAccountTags(payload.instagramId)
                .then(response => {
                    commit('SET_ACCOUNTS_TAGS', {tags: response.data.tags,});

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },

    createAccountTags({commit}, {campaignId, params}) {
        return new Promise((resolve, reject) => {
            DirectService
                .createAccountTags(campaignId, params)
                .then(response => {
                    commit('ADD_NEW_TAG', response.data.tag);

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
    updateAccountTag({commit}, {campaignId, tagId, data, tag}) {
        return new Promise((resolve, reject) => {
            DirectService
                .updateAccountTags(campaignId, tagId, data)
                .then(response => {
                    commit('UPDATE_TAG', {data, tag, tagId});

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteAccountTags({commit}, {campaignId, tagId, threadId, index}) {
        return new Promise((resolve, reject) => {
            DirectService
                .deleteAccountTags(campaignId, tagId)
                .then(response => {
                    commit('DELETE_TAG', {threadId, index})

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
    manageAccountDirectState({commit}, payload) {
        return new Promise((resolve, reject) => {
            DirectService
                .manageAccountDirectState(payload.instagramId, payload.params)
                .then(response => {
                    commit('SET_ACCOUNTS', response.data);

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
    // eslint-disable-next-line no-empty-pattern
    handleSetAppState({}, payload) {
        return new Promise((resolve, reject) => {
            DirectService
                .setAppState(payload.instagramId, payload.params)
                .then(response => {

                    return resolve(response.data);
                })
                .catch(error => reject(error))
        })
    },
};

const getters = {
    loadingState: state => {
        return {
            text: state.loadingState.text,
            status: state.loadingState.status,
            isError: state.loadingState.status === 'error',
            isPending: state.loadingState.status === 'pending',
            isSuccess: state.loadingState.status === 'success',
            noSuccess: ['pending', 'error'].includes(state.loadingState.status),
        }
    },
    getAccountLoadingState: state => state.accountLoadingState,
    getActiveSlot: state => state.activeSlot,
    getProductAccounts: (state, getters, rootState, rootGetters) => {
        const { accounts } = rootGetters['billing/getBillingPlanProductInfo']('directmessages');

        return accounts.map(acc => rootGetters['instagram/getIgAccountById'](acc));
    },
    getTags: (state) => threadId => state.tags[threadId] || [],
    getAllTags: (state) => state.tags,
    getAccounts: (state) => state.accounts,
    getAccountInbox: state => accountId => state.accounts[accountId],
    getAccountRequest: state => accountId => state.requestInbox[accountId],
    getRequestShow: state => state.handleRequestShow,
    getDetailBlock: state => state.handleDetailBlock,
    handleRequestLoader: state => state.handleRequestLoader,
    handleRequestOnActionLoader: state => state.handleRequestOnActionLoader,
    handleRequestMultipleSelect: state => state.handleRequestMultipleSelect,
    getSelectedUser: (state) => state.selectedUser,
    getSelectedUserBio: (state) => state.selectedUserBiography,
    getCount: (state) => state.count,
    selectedThreadId: (state) => state.selectedThreadId,
    getMediaFetch: (state) => state.handleMediaFetch,
    getActionLogs: (state) => [...state.actionLogs],
    getConvertVideoStatus: (state) => state.convertVideo,
    getUploadMediaProgress: (state) => state.uploadMediaProgress,
    getOpenedModal: (state) => state.handleOpenedModal,
    getActiveAccount: (state, getters, rootState, rootGetters) => {
        if (state.activeSlot.type === 'slot') return false;

        return rootGetters['instagram/getIgAccountById'](state.activeSlot.slotId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
