import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';

const io = sailsIOClient(socketIOClient);

io.sails.url = process.env.VUE_APP_API;
io.sails.query = `nosession=${ process.env.NODE_ENV === 'development' }`;
io.sails.autoConnect = false;
io.sails.reconnection = true;
io.sails.transports = ['websocket'];
io.sails.environment = process.env.NODE_ENV;

export default io.sails;
