import ApiClient from '@/libs/http-client';

const DirectService = {
    loadRequestInbox(instagramId, state) {
        return ApiClient.get(`/direct-messages/${instagramId}/pending?state=${state}`);
    },
    loadDirectInbox(instagramId, state) {
        return ApiClient.get(`/direct-messages/${ instagramId }/inbox?state=${ state }`);
    },
    loadThreadInbox(instagramId, threadId, oldestCursor, config) {
        return ApiClient.get(`/direct-messages/${instagramId}/inbox/${threadId}/thread?oldestCursor=${oldestCursor}`, config);
    },
    sendDirectMessage(instagramId, data) {
        return ApiClient.post(`/direct-messages/${ instagramId }/message`, data);
    },
    manageAccountDirectState(instagramId, data) {
        return ApiClient.post(`/direct-messages/${ instagramId }/state`, data);
    },


    hideThread(instagramId, threadId){
        return ApiClient.get(`/direct-messages/${instagramId}/thread/${threadId}/delete`);
    },
    createThread(instagramId, data){
        return ApiClient.post(`/direct-messages/${instagramId}/thread/create`, data);
    },
    leaveFromGroupThread(instagramId, threadId){
        return ApiClient.get(`/direct-messages/${instagramId}/thread/${threadId}/leave`);
    },
    addUserIntoGroupThread(instagramId, threadId, data){
        return ApiClient.post(`/direct-messages/${instagramId}/thread/${threadId}/add`, data);
    },
    editTheadTitle(instagramId, threadId, data){
        return ApiClient.post(`/direct-messages/${instagramId}/thread/${threadId}/title`, data);
    },
    markedItemSeen(instagramId, threadId, threadItemId, config){
        return ApiClient.get(`/direct-messages/${instagramId}/thread/${threadId}/item/${threadItemId}/seen`, config);
    },
    unsendDirectThreadMessage(instagramId, threadId,itemId) {
        return ApiClient.get(`/direct-messages/${instagramId}/thread/${threadId}/item/${itemId}/delete`);
    },
    sendDirectThreadMessage(msgType, instagramId, threadId, data, config) {
        return ApiClient.post(`/direct-messages/${ instagramId }/thread/${ threadId }/${ msgType }`, data, config);
    },
    sendDirectThreadMessageLink(campaignId, threadId, data) {
        return ApiClient.post(`/direct-messages/${ campaignId }/thread/${ threadId }/link`, data);
    },

    getInstagramProfileDescription(instagramId, threadId, config) {
        return ApiClient.get(`/instagram/${instagramId}/search/${threadId}/id`, config );
    },

    loadAccountTags(instagramId) {
        return ApiClient.get(`/direct-messages/${instagramId}/tags`);
    },
    createAccountTags(campaignId, data) {
        return ApiClient.post(`/direct-messages/${campaignId}/tags`, data);
    },
    updateAccountTags(campaignId, tagId, data) {
        return ApiClient.post(`/direct-messages/${campaignId}/${tagId}/tags`, data);
    },
    deleteAccountTags(campaignId, tagId) {

        return ApiClient.delete(`/direct-messages/${campaignId}/${tagId}/tags`);
    },
    /* thread --- requests */
    requestThreadService(instagramId, threadId, action) {
        return ApiClient.get(`/direct-messages/${instagramId}/thread/${threadId}/${action}`);
    },
    requestDeclineAllThread(instagramId) {
        return ApiClient.get(`/direct-messages/${instagramId}/thread/all/decline`);
    },
    requestApproveMultipleThread(instagramId, data) {
        return ApiClient.post(`/direct-messages/${instagramId}/thread/multiple/approve`, data);
    },
    setAppState(instagramId, data){
        return ApiClient.post(`/direct-messages/${instagramId}/app-state`, data)
    },
};

export default DirectService;
