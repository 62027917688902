import HashtagService from '@/services/api/hashtag.service';

const initialState = {
    loadingState: {
        status: 'pending',
        text: 'loading',
    },
    accountLoadingState: null,
    activeSlot: {
        type: null, //slot, account,
        slotId: null // slot id or account id
    },
    templates: []
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    SET_ACTIVE_SLOT(state, payload) {
        Object.assign(state.activeSlot, payload);
    },
    SET_LOADING_STATE(state, payload) {
        Object.assign(state.loadingState, payload);
    },
    SET_ACCOUNT_LOADING_STATE(state, accountId) {
        state.accountLoadingState = accountId;
    },
    ADD_TEMPLATE(state, payload) {
        state.templates.push(payload);
    },
    SET_TEMPLATES(state, payload) {
        if (!state.templates.length) state.templates = [].concat(payload)
        else {

            for (const tmpl of payload) {
                const tempIdx = state.templates.findIndex(i => i.id === payload.id);

                if (tempIdx) state.templates.splice(tempIdx, 1, tmpl)
                else state.templates.push(tmpl);
            }
        }
    },
    UPDATE_TEMPLATE(state, payload) {
        const tempIdx = state.templates.findIndex(i => i.id === payload.id);
        const template = state.templates.find(i => i.id === payload.id);

        Object.assign(template, payload);

        state.templates.splice(tempIdx, 1, template);
    },
    REMOVE_TEMPLATE(state, payload) {
        const tempIdx = state.templates.findIndex(i => i.id === payload);

        state.templates.splice(tempIdx, 1);
    }
};

const actions = {
    // eslint-disable-next-line no-empty-pattern
    searchHashtags({}, data) {
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         resolve([{
        //             "id": "82f7c791-8579-41f1-9d72-6bd287928b05",
        //             "formattedMediaCount": 659369,
        //             "mediaCount": 942082,
        //             "name": "Fintone",
        //             "profilePicUrl": "http://dummyimage.com/247x100.png/cc0000/ffffff"
        //         }, {
        //             "id": "b72a750a-aef5-4c51-a5f7-cb06ffb15db3",
        //             "formattedMediaCount": 402550,
        //             "mediaCount": 758176,
        //             "name": "Greenlam",
        //             "profilePicUrl": "http://dummyimage.com/144x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "2bdbd0d8-f0ea-46db-9d75-6934d9636bda",
        //             "formattedMediaCount": 923434,
        //             "mediaCount": 288343,
        //             "name": "Overhold",
        //             "profilePicUrl": "http://dummyimage.com/182x100.png/dddddd/000000"
        //         }, {
        //             "id": "2ee7ec7c-0eb5-495c-9210-b0066c78940c",
        //             "formattedMediaCount": 488826,
        //             "mediaCount": 518984,
        //             "name": "Solarbreeze",
        //             "profilePicUrl": "http://dummyimage.com/120x100.png/ff4444/ffffff"
        //         }, {
        //             "id": "20cf256b-ecfe-420c-b42e-11bff53afd02",
        //             "formattedMediaCount": 873194,
        //             "mediaCount": 205248,
        //             "name": "Hatity",
        //             "profilePicUrl": "http://dummyimage.com/214x100.png/dddddd/000000"
        //         }, {
        //             "id": "a547226d-609e-4798-b40e-5458f33dc87a",
        //             "formattedMediaCount": 458236,
        //             "mediaCount": 225583,
        //             "name": "Tampflex",
        //             "profilePicUrl": "http://dummyimage.com/149x100.png/ff4444/ffffff"
        //         }, {
        //             "id": "53199134-8556-41c0-b625-ab19154fe5ea",
        //             "formattedMediaCount": 818806,
        //             "mediaCount": 731027,
        //             "name": "Hatity",
        //             "profilePicUrl": "http://dummyimage.com/147x100.png/dddddd/000000"
        //         }, {
        //             "id": "9f9e87ec-62f6-4b01-9374-5cd00ceb4074",
        //             "formattedMediaCount": 860856,
        //             "mediaCount": 135181,
        //             "name": "Pannier",
        //             "profilePicUrl": "http://dummyimage.com/118x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "54bb2939-b3a8-406e-ad1f-8d90c6a11039",
        //             "formattedMediaCount": 307626,
        //             "mediaCount": 541541,
        //             "name": "Flowdesk",
        //             "profilePicUrl": "http://dummyimage.com/166x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "7b138757-0a63-4af8-8860-0ffa348a1750",
        //             "formattedMediaCount": 485889,
        //             "mediaCount": 284387,
        //             "name": "Bytecard",
        //             "profilePicUrl": "http://dummyimage.com/245x100.png/ff4444/ffffff"
        //         }, {
        //             "id": "c0d5d876-2061-410f-973d-d42b652c0a53",
        //             "formattedMediaCount": 362936,
        //             "mediaCount": 225114,
        //             "name": "Otcom",
        //             "profilePicUrl": "http://dummyimage.com/115x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "6e9b85e6-7e0d-4ef1-b3c8-ee6fe499c15b",
        //             "formattedMediaCount": 723530,
        //             "mediaCount": 342017,
        //             "name": "Zontrax",
        //             "profilePicUrl": "http://dummyimage.com/242x100.png/ff4444/ffffff"
        //         }, {
        //             "id": "d39a9dd7-47b6-43ba-b1df-5c391a5d6936",
        //             "formattedMediaCount": 114727,
        //             "mediaCount": 210986,
        //             "name": "Namfix",
        //             "profilePicUrl": "http://dummyimage.com/232x100.png/cc0000/ffffff"
        //         }, {
        //             "id": "83ec980f-5b8e-469e-9f0a-2048f56273e9",
        //             "formattedMediaCount": 391192,
        //             "mediaCount": 504516,
        //             "name": "Sonair",
        //             "profilePicUrl": "http://dummyimage.com/110x100.png/cc0000/ffffff"
        //         }, {
        //             "id": "2a33a453-75c9-4e70-9bb1-5f1b851c4ea1",
        //             "formattedMediaCount": 610758,
        //             "mediaCount": 136950,
        //             "name": "Viva",
        //             "profilePicUrl": "http://dummyimage.com/144x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "71e550cb-e0bf-43e0-ba5a-4f2f1ca1f0fe",
        //             "formattedMediaCount": 39832,
        //             "mediaCount": 156279,
        //             "name": "Asoka",
        //             "profilePicUrl": "http://dummyimage.com/241x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "055d1c38-b928-4ff4-8248-fdb89a081304",
        //             "formattedMediaCount": 527001,
        //             "mediaCount": 450624,
        //             "name": "Daltfresh",
        //             "profilePicUrl": "http://dummyimage.com/164x100.png/5fa2dd/ffffff"
        //         }, {
        //             "id": "109271a5-06b7-438f-b764-1af1e2001dc4",
        //             "formattedMediaCount": 795562,
        //             "mediaCount": 765185,
        //             "name": "Tempsoft",
        //             "profilePicUrl": "http://dummyimage.com/114x100.png/dddddd/000000"
        //         }, {
        //             "id": "392d2800-6573-45c8-9fe1-356cb12a5f5f",
        //             "formattedMediaCount": 605842,
        //             "mediaCount": 937285,
        //             "name": "Matsoft",
        //             "profilePicUrl": "http://dummyimage.com/155x100.png/cc0000/ffffff"
        //         }, {
        //             "id": "2f537930-d2c7-4b69-a4a3-1ec5d42bed51",
        //             "formattedMediaCount": 95674,
        //             "mediaCount": 994883,
        //             "name": "Viva",
        //             "profilePicUrl": "http://dummyimage.com/106x100.png/ff4444/ffffff"
        //         }])
        //     }, 300)
        // });
        return new Promise((resolve, reject) => {
            HashtagService
                .searchHashtags(data)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        });
    },
    getSavedTemplates({commit}) {
        return new Promise((resolve, reject) => {
            HashtagService
                .getSavedTemplates()
                .then(response => {
                    commit('SET_TEMPLATES', response.data);

                    return resolve(response.data);
                })
                .catch(error => reject(error.message));
        });
    },
    createNewTemplate({commit}, data) {
        return new Promise((resolve, reject) => {
            HashtagService
                .createNewTemplate(data)
                .then(response => {
                    commit('ADD_TEMPLATE', response.data.template);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message));
        });
    },
    updateSavedTemplate({commit}, data) {
        return new Promise((resolve, reject) => {
            HashtagService
                .updateSavedTemplate(data.id, {label: data.label, template: data.template})
                .then(response => {
                    commit('UPDATE_TEMPLATE', response.data.template);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message));
        });
    },
    removeSavedTemplate({commit}, templateId) {
        return new Promise((resolve, reject) => {
            HashtagService
                .removeSavedTemplate(templateId)
                .then(response => {
                    commit('REMOVE_TEMPLATE', templateId);

                    return resolve(response.data.message);
                })
                .catch(error => reject(error.message));
        });
    },
    setProductActiveSlot({ commit }, payload) {
        commit('SET_ACTIVE_SLOT', payload);

        return Promise.resolve();
    },
    setProductLoadingState({ commit }, data) {
        commit('SET_LOADING_STATE', data);

        return Promise.resolve();
    },
};

const getters = {
    getTemplatesList: state => state.templates,
    loadingState: state => {
        return {
            text: state.loadingState.text,
            status: state.loadingState.status,
            isError: state.loadingState.status === 'error',
            isPending: state.loadingState.status === 'pending',
            isSuccess: state.loadingState.status === 'success',
            noSuccess: ['pending', 'error'].includes(state.loadingState.status),
        }
    },
    getAccountLoadingState: state => state.accountLoadingState,

    getActiveSlot: state => state.activeSlot,
    getActiveAccount: (state, getters, rootState, rootGetters) => {
        if (state.activeSlot.type === 'slot') return false;

        return rootGetters['instagram/getIgAccountById'](state.activeSlot.slotId);
    },
    getProductAccounts: (state, getters, rootState, rootGetters) => {
        const { accounts } = rootGetters['billing/getBillingPlanProductInfo']('hashtaggenerator');

        return accounts.map(acc => rootGetters['instagram/getIgAccountById'](acc));
    },
    getAccountSettings: state => state.accountsSettings,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
