import PostingService from '@/services/api/posting.service';

const requestConfig = (commit, uploadType, opts) => {
  return {
    ...opts,
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      commit('SET_UPLOAD_PROGRESS', { uploadType, percentCompleted });
    }
  }
};

const initialState = {
  loadingState: {
    status: 'pending',
    text: 'loading',
  },
  accountLoadingState: null,
  activeSlot: {
    type: null, //slot, account,
    slotId: null // slot id or account id
  },
  uploadProgress: 0,
  uploadType: ''
};

const state = {
  ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
  SET_LOGOUT_STATE(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
  SET_ACTIVE_SLOT(state, payload) {
    Object.assign(state.activeSlot, payload);
  },
  SET_LOADING_STATE(state, payload) {
    Object.assign(state.loadingState, payload);
  },
  SET_ACCOUNT_LOADING_STATE(state, accountId) {
    state.accountLoadingState = accountId;
  },
  SET_DEFAULT_UPLOAD(state) {
    state.uploadProgress = 0;
    state.uploadType = '';
  },
  SET_UPLOAD_PROGRESS(state, { uploadType, percentCompleted }) {
    state.uploadType = uploadType;
    state.uploadProgress = percentCompleted;
  }
};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  loadInstagramScheduledTasks({}, instagramId) {
    return new Promise((resolve, reject) => {
      PostingService
          .loadInstagramScheduledTasks(instagramId)
          .then(response => resolve(response.data.scheduleTasks))
          .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  loadInstagramMediaPosts({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .loadInstagramMediaPosts(payload.instagramId, payload.state)
          .then(response => resolve(response.data))
          .catch(error => reject(error.message))
    });
  },
  // eslint-disable-next-line no-empty-pattern
  loadInstagramMediaStories({}, instagramId) {
    return new Promise((resolve, reject) => {
      PostingService
          .loadInstagramMediaStories(instagramId)
          .then(response => resolve(response.data.stories))
          .catch(error => reject(error.message))
    });
  },
  // eslint-disable-next-line no-empty-pattern
  loadInstagramLocations({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .loadInstagramLocations(payload.instagramId, payload.query)
          .then(response => resolve(response.data.locations))
          .catch(error => reject(error.message))
    })
  },
  createPostScheduleTask({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .createPostScheduleTask(payload.instagramId, payload.data, config)
          .then(response => resolve(response.data))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    })
  },
  updatePostScheduleTask({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .updatePostScheduleTask(payload.taskId, payload.instagramId, payload.data, payload.status, config)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    })
  },
  publishPostNow({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .postPublishNow(payload.instagramId, payload.data, config)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    })
  },
  // eslint-disable-next-line no-empty-pattern
  publishScheduleTaskPost({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .postPublishScheduleTask(payload.taskId, payload.instagramId)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message));
    })
  },
  postUpdateAndPublishScheduleTask({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .postUpdateAndPublishScheduleTask(payload.taskId, payload.instagramId, payload.data, payload.status, config)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    });
  },
  createStoryScheduleTask({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .createStoryScheduleTask(payload.instagramId, payload.data, config)
          .then(response => resolve(response.data))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    })
  },
  // eslint-disable-next-line no-empty-pattern
  updateStoryScheduleTask({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .updateStoryScheduleTask(payload.taskId, payload.instagramId, payload.data)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message));
    })
  },
  publishStoryNow({ commit }, payload) {
    const config = requestConfig(commit, 'schedule', {
      headers: { 'Content-Disposition': 'form-data; name="media";' }
    });

    return new Promise((resolve, reject) => {
      PostingService
          .storyPublishNow(payload.instagramId, payload.data, config)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message))
          .finally(() => commit('SET_DEFAULT_UPLOAD'));
    })
  },
  // eslint-disable-next-line no-empty-pattern
  publishScheduleTaskStory({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .storyPublishScheduleTask(payload.taskId, payload.instagramId, payload.data)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message));
    })
  },
  // eslint-disable-next-line no-empty-pattern
  storyUpdateAndPublishScheduleTask({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .storyUpdateAndPublishScheduleTask(payload.taskId, payload.instagramId, payload.data)
          .then(response => resolve(response.data))
          .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  removeScheduleTask({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .deleteScheduleTask(payload.taskId, payload.instagramId)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message));
    })
  },
  // eslint-disable-next-line no-empty-pattern
  removeScheduleTaskMedia({}, payload) {
    return new Promise((resolve, reject) => {
      PostingService
          .deleteScheduleTaskMedia(payload.taskId, payload.instagramId, payload.mediaName)
          .then(response => resolve(response.data.message))
          .catch(error => reject(error.message));
    })
  },
  setProductActiveSlot({ commit }, payload) {
    commit('SET_ACTIVE_SLOT', payload);

    return Promise.resolve();
  },
  setProductLoadingState({ commit }, data) {
    commit('SET_LOADING_STATE', data);

    return Promise.resolve();
  },
};

const getters = {
  loadingState: state => {
    return {
      text: state.loadingState.text,
      status: state.loadingState.status,
      isError: state.loadingState.status === 'error',
      isPending: state.loadingState.status === 'pending',
      isSuccess: state.loadingState.status === 'success',
      noSuccess: ['pending', 'error'].includes(state.loadingState.status),
    }
  },
  getActiveSlot: state => state.activeSlot,
  getActiveAccount: (state, getters, rootState, rootGetters) => {
    if (state.activeSlot.type === 'slot') return false;

    return rootGetters['instagram/getIgAccountById'](state.activeSlot.slotId);
  },
  getAccountLoadingState: state => state.accountLoadingState,

  getProductAccounts: (state, getters, rootState, rootGetters) => {
    const { accounts } = rootGetters['billing/getBillingPlanProductInfo']('scheduleposting');

    return accounts.map(acc => rootGetters['instagram/getIgAccountById'](acc));
  },
  getAccountSettings: state => state.accountsSettings,
  getUploadProgress: state => ({ type: state.uploadType, value: state.uploadProgress })
};

export default  {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
