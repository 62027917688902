import InstagramService from '@/services/api/instagram.service';
import Bugsnag from '@bugsnag/js';

const initialState = {
	accounts: [],
	actionTasks: [],
	loadingState: null,
};

const state = {
	...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
	SET_LOADING_STATE(state, payload) {
		state.loadingState = payload;
	},
	SET_LOGOUT_STATE(state) {
		Object.assign(state, JSON.parse(JSON.stringify(initialState)));
	},
	SET_ACCOUNTS(state, payload) {
		state.accounts = payload;
		
		const errorFields = {};
		for (const accountDataKey in payload) {
			if ([
				'errorName',
				'checkpointRequired',
				'checkpoint',
				'challenge',
				'undefinedError',
				'errorMessage'
			].includes(accountDataKey)) {
				Object.assign(errorFields, {
					[`${accountDataKey}`]: payload[accountDataKey]
				});
			}
		}
		
		let errorString = '';
		for (const errorFieldKey in errorFields) {
			if (errorFields[errorFieldKey]) {
				errorString = JSON.stringify(errorFields);
			}
		}
		
		if (errorString) {
			this.$gtag.event('exception', {
				description: errorString,
				fatal: true
			});
		}
		
		// const accs = state.accounts.map(i => i.id);
		//
		// for (const acc of payload) {
		//     if (!accs.includes(acc.id)) state.accounts.push(acc);
		// }
	},
	UPDATE_ACCOUNTS(state, payload) {
		// eslint-disable-next-line no-unused-vars
		for (const account of payload) {
			const accountIdx = state.accounts.findIndex(i => i.id === payload.id);
			state.accounts.splice(accountIdx, 1, payload);
		}
	},
	ADD_ACCOUNT(state, payload) {
		
		const index = state.accounts.findIndex(account => account.id === payload.id);
		if (index === -1) {
			state.accounts.push(payload);
		} else {
			this._vm.$set(state.accounts, index, payload);
		}
	},
	UPDATE_ACCOUNT(state, payload) {
		
		const accountIdx = state.accounts.findIndex(i => i.id === payload.id);
		// state.accounts.splice(accountIdx, 1, payload);
		this._vm.$set(state.accounts, accountIdx, payload);
	},
	UPDATE_ACCOUNT_CHALLENGE(state, payload) {
		const account = state.accounts.find(acc => acc.id === payload.instagramId);
		Object.assign(account, {...payload.data});
	},
	
	SET_ACCOUNTS_TASKS(state, payload) {
		for (const task of payload) {
			if (!state.actionTasks.includes(task)) state.actionTasks.push(task);
		}
	},
	ADD_ACCOUNT_TASK(state, payload) {
		state.actionTasks.push(payload);
	},
	REMOVE_ACCOUNT_TASK(state, taskId) {
		const taskIdx = state.actionTasks.findIndex(i => i.id === taskId);
		
		state.actionTasks.splice(taskIdx, 1);
	}
};

const actions = {
	async getAccountsList({commit, dispatch}) {
		commit('SET_LOADING_STATE', 'pending');
		
		const accountsResponse = await InstagramService.getAccounts();
		const {accounts} = accountsResponse.data;
		// get account profile
		Promise
			.all(
				accounts
					.map(async acc => {
						let accountData = {};
						try {
							accountData = await InstagramService.getAccountProfile(acc.id);
							
							// subscribe account to private channel
							this._vm.$eventHub.$emit('socket-instagram-connect', acc.id);
							
							return Object.assign({}, acc, accountData.data);
						} catch (e) {
							Bugsnag.notify('Cannot fetch account data', (event) => {
								event.addMetadata('Request Response', {
									...e,
									account: acc
								});
								event.context = 'Server returned an error';
								event.severity = 'info';
							});
							return Object.assign({}, acc, {
								...accountData.data, ...{
									challenge: false,
									profile: false,
									undefinedError: true,
									notFoundError: true,
									checkpointRequired: true,
									loginRequired: false,
									errorName: 'IgResponseError'
								}
							});
						}
					})
			)
			.then(accounts => {
				commit('SET_ACCOUNTS', accounts);
			})
			.catch(error => {
				console.log('err', error);
			})
			.finally(() => {
				dispatch('loadAccountTasks')
					.catch(error => console.error(error))
					.finally(() => {
						commit('SET_LOADING_STATE', 'success');
						this._vm.$eventHub.$emit('profile-ready:start-app-worker');
					});
			});
	},
	// eslint-disable-next-line no-empty-pattern
	getIgProfile({commit}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.getAccountProfile(payload.instagramId)
				.then(response => {
					
					if (payload.type === 'update') {
						commit('UPDATE_ACCOUNT', response.data);
						return resolve({message: 'Instagram Account confirmed and updated'});
					}
					
					if (payload.type === 'create') {
						this._vm.$eventHub.$emit('socket-instagram-connect', response.data.id);
						commit('ADD_ACCOUNT', response.data);
					}
					
					if (payload.type === 'create-switch') {
						this._vm.$eventHub.$emit('socket-instagram-connect', response.data.id);
						
						const account = Object.assign(payload.account, response.data);
						
						commit('ADD_ACCOUNT', account);
						
						return resolve(account);
					}
					// if (payload.type === null) {
					//     commit('UPDATE_ACCOUNT', response.data);
					//     return resolve({ message: 'Instagram Account confirmed and updated' });
					// }
					
					return resolve(response.data);
				})
				.catch(error => reject(error.messages));
		});
	},
	updateIgAccount({commit}, payload) {
		commit('UPDATE_ACCOUNT', payload.account);
	},
	createNewAccount({commit, dispatch}, payload) {
		return new Promise((resolve, reject) => {
			commit('SET_LOADING_STATE', 'pending');
			InstagramService
				.createNewAccount(payload)
				.then(response => {
					const {account, billingPlan} = response.data;
					commit('billing/SET_BILLING_PLAN', billingPlan, {root: true});
					
					dispatch('getIgProfile', {type: 'create', instagramId: account.id})
						.catch(error => {
							this._vm.$showError(error);
						})
						.finally(() => commit('SET_LOADING_STATE', 'success'));
					return resolve(response.data);
				})
				.catch((error) => {
					reject(error.message);
					commit('SET_LOADING_STATE', 'success');
				});
		});
	},
	switchTaskCreateNewAccount({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.createNewAccountActionTasks(payload)
				.then(response => {
					return dispatch('getIgProfile', {
						type: 'create-switch',
						instagramId: response.data.account.id,
						account: response.data.account
					})
						.catch(error => reject(error.message));
				})
				.then(response => resolve(response))
				.catch(error => reject(error.message));
		});
	},
	
	twoFactorAuth({commit, dispatch}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.twoFactorAuth(payload.instagramId, payload.data)
				.then(response => {
					if (payload.actionTask === 'create') {
						commit('billing/SET_BILLING_PLAN', response.data.billingPlan, {root: true});
					}
					dispatch('getIgProfile', {type: payload.actionTask, instagramId: response.data.account.id})
						.catch(error => this._vm.$showError(error))
						.finally(() => commit('SET_LOADING_STATE', 'success'));
					return resolve(response.data);
				})
				.catch(error => reject(error.message));
		});
	},
	challengeSelectVerifyMethod({commit, dispatch}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.accountChallengeSelectVerifyMethod(payload.instagramId, payload.choice, payload.reply)
				.then(response => {
					if (response.data.challenge_drop) {
						return dispatch('getIgProfile',
							{type: 'update', instagramId: payload.instagramId}
						).catch(error => reject(error.message));
					} else {
						commit('UPDATE_ACCOUNT_CHALLENGE', {
							instagramId: payload.instagramId,
							challenge: response.data.challenge
						});
						
						return resolve(response.data);
					}
				})
				.catch(error => reject(error.message));
		});
	},
	challengeSendConfirmationCode({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.accountChallengeSubmitCode(payload.instagramId, payload.code)
				.then(response => {
					this._vm.$showSuccess(response.data.message);
					
					return dispatch('getIgProfile',
						{type: 'update', instagramId: payload.instagramId}
					).catch(error => reject(error.message));
					
				})
				.catch(error => reject(error.message));
		});
	},
	challengeSubmitPhone({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.accountChallengeSubmitPhone(payload.instagramId, payload.data)
				.then(response => {
					this._vm.$showSuccess(response.data.message);
					
					return dispatch('getIgProfile',
						{type: 'update', instagramId: payload.instagramId}
					).catch(error => reject(error.message));
					
				})
				.catch(error => reject(error.message));
		});
	},
	loginVerification({dispatch, commit}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.accountLoginVerification(payload.instagramId, payload.data)
				.then(response => {
					
					if (response.data.message === 'challenge_required') {
						commit('UPDATE_ACCOUNT_CHALLENGE', {
							instagramId: payload.instagramId,
							data: {
								challenge: response.data.challenge,
								checkpoint: response.data.checkpoint,
								checkpointRequired: true,
								loginRequired: false,
							}
						});
						
						return resolve(response);
					} else {
						this._vm.$showSuccess(response.data.message);
						
						dispatch('getIgProfile',
							{type: 'update', instagramId: payload.instagramId}
						)
							.then(response => {
								resolve(response);
							})
							.catch(error => reject(error.message));
					}
				})
				.catch(error => reject(error.message));
		});
	},
	// eslint-disable-next-line no-empty-pattern
	topSearchRequest({}, {instagramId, data}) {
		return new Promise((resolve, reject) => {
			InstagramService
				.topSearchRequest(instagramId, data)
				.then(response => resolve(response.data))
				.catch(error => reject(error));
		});
	},
	// eslint-disable-next-line no-empty-pattern
	getCustomProxy({}) {
		return new Promise((resolve, reject) => {
			InstagramService
				.getCustomProxy()
				.then(response => resolve(response.data))
				.catch(error => reject(error));
		});
	},
	// eslint-disable-next-line no-empty-pattern
	checkCustomProxy({}, data) {
		return new Promise((resolve, reject) => {
			InstagramService
				.checkCustomProxy(data)
				.then(response => resolve(response.data))
				.catch(error => reject(error));
		});
	},
	// eslint-disable-next-line no-empty-pattern
	saveCustomProxy({}, data) {
		return new Promise((resolve, reject) => {
			InstagramService
				.checkCustomProxy(data)
				.then(response => resolve(response.data))
				.catch(error => reject(error));
		});
	},
	
	loadAccountTasks({commit}) {
		return new Promise((resolve, reject) => {
			InstagramService
				.loadScheduledActions()
				.then(response => {
					commit('SET_ACCOUNTS_TASKS', response.data.instagramActions);
					
					return resolve(response.data.instagramActions);
				})
				.catch(error => reject(error));
		});
	},
	createAccountTask({commit}, payload) {
		return new Promise((resolve, reject) => {
			InstagramService
				.createScheduledAction(payload)
				.then(response => {
					commit('ADD_ACCOUNT_TASK', response.data.actionTask);
					
					return resolve(response.data.message);
				})
				.catch(error => {
					console.error(error);
					reject(error.message);
				});
		});
	},
	cancelAccountTask({commit}, taskId) {
		return new Promise((resolve, reject) => {
			InstagramService
				.cancelScheduledActionTask(taskId)
				.then(response => {
					commit('REMOVE_ACCOUNT_TASK', taskId);
					
					return resolve(response.data.message);
				})
				.catch(error => reject(error.message));
		});
	},
	
	
	// eslint-disable-next-line no-empty-pattern
	resetChallenge({}, igId) {
		return new Promise((resolve, reject) => {
			InstagramService
				.accountChallengeReset(igId)
				.then(response => {
					return resolve(response);
				})
				.catch(error => reject(error.message));
		});
	},
};

const getters = {
	loadingState: state => state.loadingState === 'pending',
	getAccountsByProduct: (state, getters, rootState, rootGetters) => product => {
		const {accounts} = rootGetters['billing/getBillingPlanProductInfo'](product);
		
		return accounts.map(acc => state.accounts.find(i => i.id === acc));
	},
	getAvailableAccountsByProduct: (state, getters, rootState, rootGetters) => product => {
		const {accounts} = rootGetters['billing/getBillingPlanProductInfo'](product);
		
		return state.accounts.filter(acc => !accounts.includes(acc.id));
	},
	getIgAccountById: state => id => state.accounts.find(i => i.id === id),
	getInstagramAccountsList: state => state.accounts,
	getAccountsLoadingState: state => state.accountsLoadingState,
	getAccountsTasks: state => state.actionTasks,
	getAccountTaskById: state => instagramId => state.actionTasks.find(t => t.source === instagramId),
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
