import Axios from 'axios';
import store from '@/store';

const options = {
	baseURL: `${process.env.VUE_APP_API}`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8'
	},
};

const httpClient = Axios.create(options);


const setBearer = (token) => {
	const isBearer = RegExp('\\b'+ "Bearer" +'\\b').test(token);

	return isBearer ? token : `Bearer ${token}`;
}

httpClient.interceptors.request.use((config) => {
	if (location.protocol === 'https:') config.withCredentials = true;

	if (store.getters['user/userAccessToken']) {
        config.headers['authorization'] = setBearer(`${store.getters['user/userAccessToken']}`);
    }

	return config;
});


httpClient.interceptors.response.use(
	function (response) {
		if (response.config.headers['authorization']) {
        store.commit('user/SET_ACCESS_TOKEN', setBearer(`${response.config.headers['authorization']}`));
    }

    return response;
    },
    function(error) {
        if (error.code && error.code === 'ERR_CANCELED') return Promise.reject(error);

		const { data, status } = error.response;

		if (status === 401) {
			store
				.dispatch('app/clearAppStorage')
				.then(() => window.location.replace('/auth/signin?reason=session_expired'));
		} else return Promise.reject(data);
	}
);

const ApiClient = {
	get(url, config = {}) {
		return httpClient.get(url, config);
	},

	put(url, data = {}, config = {}) {
		return httpClient.put(url, data, config);
	},

	post(url, data = {}, config = {}) {
		return httpClient.post(url, data, config);
	},

	delete(url, data = {}) {
		return httpClient.delete(url, data);
	},
};

export default ApiClient;
