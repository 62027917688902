import ApiClient from '@/libs/http-client';

const InstagramService = {
  endpoints(route, param) {
    const url = {
      'get-accounts-tasks': '/api/v1/instagram/acoount/tasks',
      accountsFind: '/api/v1/instagram',
      getToken: '/api/v1/instagram/auth',
      authHandle: '/api/v1/instagram/auth',
      main: '/instagram',
      destroy: `/api/v1/instagram/?id=${ param }`,
      putCreate: `/api/v1/instagram/${ param }`,
      update: '/api/v1/instagram/stayconnected',
      subscribe: `/api/v1/instagram/${ param }/subscribe`,
      topsearch: `/api/v1/instagram/topsearch`
    };

    return url[route];
  },

  getAccounts() {
    return ApiClient.get('/instagram/accounts');
  },
  getAccountProfile(instagramId) {
    return ApiClient.get(`/instagram/${ instagramId }/profile`);
  },
  createNewAccount(payload) {
    return ApiClient.post('/instagram/create', payload);
  },
  accountChallengeReset(instagramId) {
    return ApiClient.get(`/instagram/${ instagramId }/challenge/reset`)
  },
  accountChallengeSelectVerifyMethod(instagramId, choice, reply) {
    return ApiClient.get(`/instagram/${ instagramId }/challenge/verify/${ choice }/select?reply=${ reply }`);
  },
  accountChallengeSubmitCode(instagramId, code) {
    return ApiClient.get(`/instagram/${ instagramId }/challenge/code/${ code }/submit`)
  },
  accountChallengeSubmitPhone(instagramId, data) {
    return ApiClient.post(`/instagram/${ instagramId }/challenge/phone/submit`, data)
  },
  accountLoginVerification(instagramId, data) {
    return ApiClient.post(`/instagram/${ instagramId }/verification/login`, data);
  },

  topSearchRequest( instagramId, data) {
    return ApiClient.post(`/instagram/${ instagramId }/topsearch`, data)
  },
  getCustomProxy() {
    return ApiClient.get(`/instagram/custom-proxy`);
  },
  checkCustomProxy(data) {
    return ApiClient.post(`/instagram/custom-proxy/check`, data);
  },
  saveCustomProxy(data) {
    return ApiClient.post(`/instagram/custom-proxy/save`, data);
  },
  createNewAccountActionTasks(data) {
    return ApiClient.post('/instagram/tasks/create', data);
  },
  loadScheduledActions() {
    return ApiClient.get('/instagram/scheduled');
  },
  createScheduledAction(data) {
    return ApiClient.post(`/instagram/scheduled`, data)
  },
  cancelScheduledActionTask(taskId) {
    return ApiClient.get(`/instagram/scheduled/${ taskId }/cancel`);
  },
  twoFactorAuth(instagramId, data) {
    return ApiClient.post(`/instagram/twoFactorAuth/${ instagramId }`, data);
  },
  //checkYourGrowthSection
  handleLoadInstagramAccountsSearch(accName) {
    return ApiClient.get(`/landing/check-your-growth/accounts?q=${ accName }`);
  },
  handleLoadCheckYourGrowthStatistics(accId) {
    return ApiClient.get(`/landing/check-your-growth/${ accId }/statistics`);
  },

}

export default InstagramService;
