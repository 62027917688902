import ApiClient from '@/libs/http-client';

const BillingService = {
  calculatePlanPrice(data) {
    return ApiClient.post('/billing/plan/calculate', data);
  },

  getBillingPlan() {
    return ApiClient.get('/billing/plans');
  },
  getBillingPlanWithTransactions() {
    return ApiClient.get('/billing/plans/transactions');
  },
  createServicePlan(data) {
    return ApiClient.post('/billing/plan/create', data);
  },
  updateServicePlan(data) {
    return ApiClient.post('/billing/plan/update', data);
  },


  getBillingTransactions(planId) {
    return ApiClient.get(`/billing/plans/${planId}/payments`);
  },
  downloadInvoicePdf(paymentId) {
    return ApiClient.get(`/billing/payments/${ paymentId }/pdf`, { responseType: 'blob' })
  },

  proceedPaypalPayment(data) {
    return ApiClient.post('/billing/payments/paypal/proceed', data);
  },


  setInstagramAccountInServiceSlot(instagramId, product) {
    return ApiClient.post(`/billing/plans/${ product }/instagram/${ instagramId }/set-account`);
  },

  //Paysimple methods
  createPaysimpleUser(data){
    return ApiClient.post('/billing/customer', data);
  },
  updatePaysimpleUser(data){
    return ApiClient.put('/billing/customer', data);
  },
  addCreditCardPaysimple(data){
    return ApiClient.post('/billing/card', data);
  },
  updateCreditCardPaysimple(data){
    return ApiClient.put('/billing/card', data);
  },
  deleteCreditCardPaysimple(cardId){
    return ApiClient.delete(`/billing/card/${ cardId }`, );
  },

  payByPaysimple(data){
    return ApiClient.post('/billing/payments/paysimple', data);
  }
}

export default BillingService;
