import localforage from 'localforage';
import store from '@/store';

const indexedDb = localforage.createInstance({
    name: 'insocial-app',
    version: 1.0,
    storeName: 'direct-messages'
});

indexedDb
    .setDriver([localforage.INDEXEDDB])
    .then(() => {
        store.commit('app/SET_IDB', {
            status: 'success',
        });
    })
    .catch(error => store.commit('app/SET_IDB', {
        status: 'error',
        stateMessage: error,
    }));

export default indexedDb;
