import ApiClient from '@/libs/http-client';

const CommentService = {
  loadAccountMediaFeed(instagramId, state) {
    return ApiClient.get(`/comment-tracker/${ instagramId }/feed?state=${ state }`);
  },
  loadAccountMediaComments(instagramId, postId, state) {
    return ApiClient.get(`/comment-tracker/${ instagramId }/${ postId }/comments/feed?state=${ state }`);
  },
  loadAccountMediaChildComments(instagramId, mediaId, commentId, state) {
    return ApiClient.get(`/comment-tracker/${ instagramId }/media/${ mediaId }/comments/${ commentId }/child/feed?state=${ state }`);
  },
  toggleMediaCommentLike(instagramId, commentId, reaction) {
    return ApiClient.get(`/comment-tracker/${ instagramId }/comment/${ commentId }/${ reaction }`); //like | unlike
  },
  mediaCommentReply(instagramId, mediaId, commentId, data) {
    return ApiClient.post(`/comment-tracker/${ instagramId }/media/${ mediaId }/comments/${ commentId }/reply`, data);
  }
};

export default CommentService;
