import moment from 'moment';
import AutopromotionService from '@/services/api/autopromotion.service';

const initialState = {
    loadingState: {
        status: 'pending',
        text: 'loading',
    },
    accountLoadingState: null,
    activeSlot: {
        type: null, //slot, account,
        slotId: null // slot id or account id
    },
    accountsSettings: {},
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    SET_ACTIVE_SLOT(state, payload) {
        Object.assign(state.activeSlot, payload);
    },
    SET_LOADING_STATE(state, payload) {
        Object.assign(state.loadingState, payload);
    },
    SET_ACCOUNT_LOADING_STATE(state, accountId) {
        state.accountLoadingState = accountId;
    },
    ADD_CAMPAIGN_LOG(state, payload) {
        state.accountsSettings[payload.instagramId].logs.unshift(payload.text);
    },
    ADD_CAMPAIGN_SCORE(state, payload) {
        state.accountsSettings[payload.instagramId][payload.type].push(payload.scores);
    },
    SET_CAMPAIGN_SETTINGS(state, {accountId, data}) {
        this._vm.$set(state.accountsSettings, accountId, data);
    },

    ADD_CAMPAIGN_SOURCE(state, { instagramId, sources }) {
        Object.assign(state.accountsSettings[instagramId], { sources });
    },
    CHANGE_CAMPAIGN_SOURCES(state, { instagramId, sourceId }) {
        const sourceIdx = state.accountsSettings[instagramId].sources.findIndex(i => i.id === sourceId);

        state.accountsSettings[instagramId].sources.splice(sourceIdx, 1);
    },
    CHANGE_CAMPAIGN_CURRENT_SOURCE(state, {instagramId, currentSourceId}) {
        Object.assign(state.accountsSettings[instagramId], {currentSourceId});
    },
    CHANGE_CAMPAIGN_SETTINGS(state, {instagramId, settings}) {
        Object.assign(state.accountsSettings[instagramId], {...settings});
    }
};

const actions = {
    fetchCampaign({commit}, account) {
        commit('SET_LOADING_STATE', {
            status: 'pending',
            text: 'loading'
        });

        return new Promise((resolve, reject) => {
            AutopromotionService
                .fetchCampaign(account)
                .then(response => {
                    const answerTemplate = response.data.storyQuestionAnswerTemplate;
                    response.data.storyQuestionAnswerTemplate = answerTemplate ? answerTemplate : '';

                    commit('SET_CAMPAIGN_SETTINGS', { accountId: account, data: response.data });
                    // commit('SET_LOADING_STATE', {
                    //     status: 'success',
                    //     text: 'ok'
                    // });
                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    handleCampaignSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            AutopromotionService
                .manageCampaign(data.instagramId, data.settings)
                .then(response => {
                    const { instagramId, settings, message } = response.data;

                    commit('CHANGE_CAMPAIGN_SETTINGS', { instagramId, settings });

                    return resolve(message);
                })
                .catch(error => reject(error));
        })
    },
    addCampaignSource({commit}, payload) {
        return new Promise((resolve, reject) => {
            AutopromotionService
                .addCampaignSource(payload.instagramId, payload.data)
                .then(response => {
                    commit('ADD_CAMPAIGN_SOURCE', {
                        instagramId: payload.instagramId,
                        sources: response.data.source
                    });

                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    removeCampaignSource({commit}, payload) {
        return new Promise((resolve, reject) => {
            AutopromotionService
                .deleteCampaignSource(payload.instagramId, payload.sourceId)
                .then(response => {
                    commit('CHANGE_CAMPAIGN_SOURCES', payload);

                    return resolve(response.data);
                })
                .catch(error => reject(error));
        })
    },
    setProductActiveSlot({ commit }, payload) {
        commit('SET_ACTIVE_SLOT', payload);

        return Promise.resolve();
    },
    setAccountLoadingState({ commit }, accountId) {
        commit('SET_ACCOUNT_LOADING_STATE', accountId);
    },
    setProductLoadingState({ commit }, data) {
        commit('SET_LOADING_STATE', data);

        return Promise.resolve();
    },
    socketAddCampaignLogs({commit}, data) {
        commit('ADD_CAMPAIGN_LOG', data);
    },
    socketChangeCampaignCurrentSource({commit}, payload) {
        commit('CHANGE_CAMPAIGN_CURRENT_SOURCE', payload)
    },
    socketChangeCampaignSettings({commit}, payload) {
        commit('CHANGE_CAMPAIGN_SETTINGS', payload);
    },
    socketAddCampaignScore({commit}, payload) {
        commit('ADD_CAMPAIGN_SCORE', payload)
    },
};

const getters = {
    loadingState: state => {
        return {
            text: state.loadingState.text,
            status: state.loadingState.status,
            isError: state.loadingState.status === 'error',
            isPending: state.loadingState.status === 'pending',
            isSuccess: state.loadingState.status === 'success',
            noSuccess: ['pending', 'error'].includes(state.loadingState.status),
        }
    },
    getActiveSlot: state => state.activeSlot,
    getActiveAccount: (state, getters, rootState, rootGetters) => {
        if (state.activeSlot.type === 'slot') return false;

        return rootGetters['instagram/getIgAccountById'](state.activeSlot.slotId);
    },
    getProductAccounts: (state, getters, rootState, rootGetters) => {
        const { accounts } = rootGetters['billing/getBillingPlanProductInfo']('autoliking');

        return accounts.map(acc => rootGetters['instagram/getIgAccountById'](acc));
    },
    getAccountSettings: state => state.accountsSettings,
    getAccountLoadingState: state => state.accountLoadingState,
    getCampaignLogsById: state => accountId => {
        const timezoneOffset = new Date().getTimezoneOffset();
        const emptyLog = [`[${ new Date().toLocaleString()}] (info) No logs found for current date`];
   
        if (!state.accountsSettings[+accountId]?.logs.length) return emptyLog;

        return state.accountsSettings[accountId].logs
            .slice(0, 50)
            .filter(item => !item.includes('Could not find any entity') && item.indexOf('Could not find any entity of type') === -1)
            .map(record => {
                let [logDate, logText] = record.split('] ');
                const formattedDateTime = moment(Number(logDate.replace('[', ''))).utcOffset(timezoneOffset).format();

                logDate = new Date(formattedDateTime).toLocaleString();

                return `[${ logDate }] ${ logText }`;
            });
    },
    getCampaignSourcesByID: state => accountId => state.accountsSettings[accountId]?.sources ?? [],
    getCampaignActiveSourceId: state => accountId => state.accountsSettings[accountId].currentSourceId
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
