import CommentService from '@/services/api/comment.service';

const initialState = {
    loadingState: {
        status: 'pending',
        text: 'loading',
    },
    accountLoadingState: null,
    activeSlot: {
        type: null, //slot, account,
        slotId: null // slot id or account id
    }
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    SET_ACTIVE_SLOT(state, payload) {
        Object.assign(state.activeSlot, payload);
    },
    SET_LOADING_STATE(state, payload) {
        Object.assign(state.loadingState, payload);
    },
    SET_ACCOUNT_LOADING_STATE(state, accountId) {
        state.accountLoadingState = accountId;
    },
};

const actions = {
    // eslint-disable-next-line no-empty-pattern
    loadAccountMediaFeed({}, { instagramId, state }) {
        // if (!instagramId) return

        return new Promise((resolve, reject) => {
            CommentService
                .loadAccountMediaFeed(instagramId, state)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    loadAccountMediaComments({}, { instagramId, postId, state }) {
        return new Promise((resolve, reject) => {
            CommentService
                .loadAccountMediaComments( instagramId, postId, state )
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    loadAccountMediaChildComments({}, { instagramId, mediaId, commentId, state }) {
        return new Promise((resolve, reject) => {
            CommentService
                .loadAccountMediaChildComments(instagramId, mediaId, commentId, state)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    toggleMediaCommentLike({}, { instagramId, commentId, reaction }) {
        return new Promise((resolve, reject) => {
            CommentService
                .toggleMediaCommentLike(instagramId, commentId, reaction)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    mediaCommentReply({}, { instagramId, mediaId, commentId, data }) {
        return new Promise((resolve, reject) => {
            CommentService
                .mediaCommentReply(instagramId, mediaId, commentId, data)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        })
    },
    setProductActiveSlot({ commit }, payload) {
        commit('SET_ACTIVE_SLOT', payload);

        return Promise.resolve();
    },
    setProductLoadingState({ commit }, data) {
        commit('SET_LOADING_STATE', data);

        return Promise.resolve();
    },
};

const getters = {
    loadingState: state => {
        return {
            text: state.loadingState.text,
            status: state.loadingState.status,
            isError: state.loadingState.status === 'error',
            isPending: state.loadingState.status === 'pending',
            isSuccess: state.loadingState.status === 'success',
            noSuccess: ['pending', 'error'].includes(state.loadingState.status),
        }
    },
    getActiveSlot: state => state.activeSlot,
    getAccountLoadingState: state => state.accountLoadingState,

    getActiveAccount: (state, getters, rootState, rootGetters) => {
        if (state.activeSlot.type === 'slot') return false;

        return rootGetters['instagram/getIgAccountById'](state.activeSlot.slotId);
    },
    getProductAccounts: (state, getters, rootState, rootGetters) => {
        const { accounts } = rootGetters['billing/getBillingPlanProductInfo']('commenttracker');

        return accounts.map(acc => rootGetters['instagram/getIgAccountById'](acc));
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
