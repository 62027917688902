export default {
    nameValidFormat: '{attribute} must not contain digits or symbols',
    twoCharactersInRow: '{attribute} must not have more than two identical characters in a row',
    email: 'Email format is invalid',
    required: '{attribute} is required',
    minValue: '{attribute} min value can`t be less {min}',
    maxValue: '{attribute} max value can`t be greater {max}',
    minLength: '{attribute} must be at least {min} characters',
    maxLength: '{attribute} must be no more than {max} characters',
    validFormat: '{attribute} must contain the first capital letter, must be contain only characters',
    validPassword: '{attribute} must contain at least one digit and uppercase character',
    sameAsPassword: 'Passwords must be identical',
    numeric: '{attribute} is invalid. Must be a digit',
    url: 'URL format is invalid',
    ipAddress: 'IP-address format is invalid',
    hostFormat: `{attribute} is invalid, can be like IP-address or URL`,
    zipFormat: `{attribute} can only contain numbers and the "-" character`,
}
