import _ from 'lodash';
import Vue from "vue";

import BillingService from '@/services/api/billing.service';

const initialState = {
    billingPlan: {},
    billingTransactions: [],
    paysimpleSettings: {},
};

const state = {
    ...JSON.parse(JSON.stringify(initialState)),
};

const mutations = {
    SET_LOGOUT_STATE(state) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    SET_BILLING_PLAN(state, payload) {
        // Object.assign(state.billingPlan, payload);
        Vue.set(state, 'billingPlan', payload);
    },
    SET_BILLING_TRANSACTIONS(state, payload) {
        state.billingTransactions = payload;
    },
    UPDATE_INFLUENCERS_CREDITS(state, payload) {
        if (payload === 'pdf') {
            state.billingPlan.credits.credits -= 25;
        } else {
            state.billingPlan.credits.credits = payload;
        }
    },
    SET_PAYSIMPLE_SETTINGS(state, payload) {
        Vue.set(state, 'paysimpleSettings', payload);
    },
    UPDATE_CREDITS_AMOUNT(state, payload){
        state.billingPlan = payload
    }
};

const actions = {
    loadBillingPlan({commit}) {
        return new Promise((resolve, reject) => {
            BillingService
                .getBillingPlan()
                .then(response => {
                    commit('SET_BILLING_PLAN', response.data.billingPlan);

                    return response.data.billingPlan;
                })
                .then(billingPlan => {
                    commit('app/SET_ALLOWED_PRODUCTS', billingPlan, {root: true});

                    return resolve(billingPlan);
                })
                .catch(error => reject(error));
        });
    },
    loadBillingPlanWithTransactions({commit}) {
        return new Promise((resolve, reject) => {
            BillingService
                .getBillingPlanWithTransactions()
                .then(response => {
                    commit('SET_BILLING_PLAN', response.data.billingPlan);
                    commit('SET_BILLING_TRANSACTIONS', response.data.payments);

                    return response.data.billingPlan;
                })
                .then(billingPlan => {
                    commit('app/SET_ALLOWED_PRODUCTS', billingPlan, {root: true});

                    return resolve(billingPlan);
                })
                .catch(error => reject(error));
        });
    },
    loadBillingTransactions({commit}, planId) {
        return new Promise((resolve, reject) => {
            BillingService
                .getBillingTransactions(planId)
                .then(response => commit('SET_BILLING_TRANSACTIONS', response.data.payments))
                .catch(error => reject(error));
        });
    },
    // eslint-disable-next-line no-empty-pattern
    downloadInvoicePdf({}, paymentId) {
        return new Promise((resolve, reject) => {
            BillingService
                .downloadInvoicePdf(paymentId)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },
    // eslint-disable-next-line no-empty-pattern
    calculateProductsPrices({}, data) {
        return new Promise((resolve, reject) => {
            BillingService
                .calculatePlanPrice(data)
                .then(response => resolve(response.data.periods))
                .catch(error => reject(error));
        })
    },
    createBillingServicePlan({commit}, data) {
        return new Promise((resolve, reject) => {
            BillingService
                .createServicePlan(data)
                .then(response => {
                    commit('SET_BILLING_PLAN', response.data.serviceBillingPlan);

                    return resolve(response.data);
                })
                .catch(error => {
                    if (!_.isUndefined(error.action)) {
                        if (error.action === 'set_plan') commit('SET_BILLING_PLAN', error.getBillingServicePlan)
                    }

                    return reject(error);
                });
        })
    },
    updateBillingServicePlan({commit}, data) {
        return new Promise((resolve, reject) => {
            BillingService
                .updateServicePlan(data)
                .then(response => {
                    commit('SET_BILLING_PLAN', response.data.billingServicePlan);

                    return resolve(response.data.message);
                })
                .catch(error => {
                    if (!_.isUndefined(error.action)) {
                        if (error.action === 'set_plan') commit('SET_BILLING_PLAN', error.getBillingServicePlan)
                    }

                    return reject(error.message);
                });
        })
    },
    proceedPayPalPayment({commit}, data) {
        return new Promise((resolve, reject) => {
            BillingService
                .proceedPaypalPayment(data)
                .then(response => {
                    const {message, payments, billingServicePlan} = response.data;

                    commit('SET_BILLING_PLAN', billingServicePlan);
                    commit('app/SET_ALLOWED_PRODUCTS', billingServicePlan, {root: true});
                    commit('SET_BILLING_TRANSACTIONS', payments);

                    return resolve({message, billingServicePlan});
                })
                .catch(error => reject(error.message));
        })
    },
    setInstagramAccountInServiceSlot({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .setInstagramAccountInServiceSlot(payload.instagramId, payload.product)
                .then(response => {
                    const {message, billingPlan} = response.data;

                    commit('SET_BILLING_PLAN', billingPlan);

                    return resolve({message, billingPlan});
                })
                .catch(error => reject(error.message));
        })
    },
    //paysimple actions
    createNewPaysimpleUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .createPaysimpleUser(payload)
                .then(response => {
                    commit('SET_PAYSIMPLE_SETTINGS', response.data.paysimple)
                    return resolve(response)
                })
                .catch(error => reject(error.message))
        })
    },
    updateNewPaysimpleUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .updatePaysimpleUser(payload)
                .then(response => {
                    commit('SET_PAYSIMPLE_SETTINGS', response.data.paysimple)
                    return resolve(response)
                })
                .catch(error => reject(error.message))
        })
    },
    addNewPaysimpleCard({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .addCreditCardPaysimple(payload)
                .then(response => {
                    commit('SET_PAYSIMPLE_SETTINGS', response.data.paysimple)
                    return resolve(response)
                })
                .catch(error => reject(error.message))
        })
    },
    deletePaysimpleCard({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .deleteCreditCardPaysimple(payload)
                .then(response => {
                    commit('SET_PAYSIMPLE_SETTINGS', response.data.paysimple)
                    return resolve(response)
                })
                .catch(error => reject(error.message))
        })
    },
    makePaymentPaysimple({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService
                .payByPaysimple(payload)
                .then(response => {
                    const {message, payments, billingServicePlan} = response.data;

                    commit('SET_BILLING_PLAN', billingServicePlan);
                    commit('app/SET_ALLOWED_PRODUCTS', billingServicePlan, {root: true});
                    commit('SET_BILLING_TRANSACTIONS', payments);
                    return resolve({message, billingServicePlan})
                })
                .catch(error => reject(error.message))
        })
    },
    updateBillingPlan({commit}, {payload}) {
        return new Promise((resolve, reject) => {
            try {
                commit('UPDATE_CREDITS_AMOUNT', payload)
                resolve()
            }catch (error) {
                reject(error)
            }
        })
    }
}


const getters = {
    getBillingServicePlan: state => state.billingPlan,
    getBillingServiceTransactions: state => state.billingTransactions,
    getBillingPlanProductInfo: state => product => state.billingPlan[product],

    getCredits: state => state.billingPlan?.credits?.credits,
    getCreditsBurnDate: state => state.billingPlan?.credits?.burn_date,

    getPaysimpleSettings: state => state.paysimpleSettings ?? false,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
