<template>
  <div id="app">
    <transition name="fade" mode="out-in">
			<router-view />
		</transition>
  </div>
</template>

<script>

  export default {
		name: 'App',
		data() {
			return {}
		},
    mounted() {}
  }
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
		transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
		opacity: 0;
}
</style>
